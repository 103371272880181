import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Empty, PageHeader, Result, Row, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { AppContext } from '../state/State';

interface ReportData {
    default: string;
    crida1: string;
    crida2: string;
    crida3: string;
    dayAhead: string;
    index: string;
}

const Reports: React.FC = props => {
    const [loading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [reportList, setReportList] = useState<Array<ReportData>>([]);

    const context = useContext(AppContext);

    const fetchList = async () => {
        setLoading(true);
        setHasError(false);

        try {
            const response = await fetch(`/api/Reports/List`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                }
            });

            if (response.status === 200) {
                const list: Array<{ default: string, dayAhead: string, crida1: string, crida2: string, crida3: string }> = await response.json();
                setReportList(list.map((x, i) => { return { default: x.default, dayAhead: x.dayAhead, crida1: x.crida1, crida2: x.crida2, crida3: x.crida3, index: i + "" }; }));
            }
            else {
                setHasError(true);
            }
        }
        catch (error) {
            setHasError(true);
        }

        setLoading(false);
    };

    const columns: ColumnsType<ReportData> = [
        {
            title: "Default",
            dataIndex: "default",
            render: x => <a target="_blank" rel="noreferrer" href={`/api/Reports/Download?type=default&date=${x}`}>{x}</a>,
        },
        {
            title: "DAM",
            dataIndex: "dayAhead",
            render: x => <a target="_blank" rel="noreferrer" href={`/api/Reports/Download?type=dayahead&date=${x}`}>{x}</a>,
        },
        {
            title: "CRIDA 1",
            dataIndex: "crida1",
            render: x => <a target="_blank" rel="noreferrer" href={`/api/Reports/Download?type=crida1&date=${x}`}>{x}</a>,
        },
        {
            title: "CRIDA 2",
            dataIndex: "crida2",
            render: x => <a target="_blank" rel="noreferrer" href={`/api/Reports/Download?type=crida2&date=${x}`}>{x}</a>,
        },
        {
            title: "CRIDA 3",
            dataIndex: "crida3",
            render: x => <a target="_blank" rel="noreferrer" href={`/api/Reports/Download?type=crida3&date=${x}`}>{x}</a>,
        },
    ];

    useEffect(() => {
        fetchList();
    }, []);

    return (
        <>
            <PageHeader
                title={context.userInfo?.organization}
                subTitle="Reports"
                style={{ paddingTop: "0" }}
            />
            {hasError &&
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Result
                            status="error"
                            title="Unable to contact server"
                            subTitle="Please check your connectivity and try again."
                            extra={[
                                <Button type="primary" key="console" onClick={fetchList}>
                                    Retry
                                </Button>
                            ]}
                        ></Result>
                    </Col>
                </Row>}

            {!hasError && (loading || reportList.length > 0) &&
                <Table rowKey="index" loading={loading} columns={columns} dataSource={reportList}></Table>}

            {!hasError && !loading && reportList.length === 0 &&
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Empty description="No reports found"></Empty>
                    </Col>
                </Row>}
        </>
    );
}

export default Reports;
