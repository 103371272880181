import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { Line } from '@ant-design/charts';
import { DatePicker, Button, Card, Form, Select, Col, Row, Spin, Result, Radio, Table, Input } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { Observation } from '../data/Observation';
import { Asset } from '../data/Asset';
import { Park } from '../data/Park';
import { AssetForecast } from '../data/AssetForecast';
import NoAssets from '../components/NoAssets';
import { AppContext } from '../state/State';
import { ForecastDomain } from '../data/ForecastDomain';
import { useForm } from 'antd/lib/form/Form';
import { ForecastFields } from '../data/ForecastFields';
import { Portfolio } from '../data/Portfolio';

const { Option, OptGroup } = Select;
const { RangePicker } = DatePicker;
type ChartField = "Power (KW)" | "Wind speed (m/sec)" | "Irradiance (W⋅m−2)" | "Temperature (°C)" | "Humidity %" | "Pressure (hPa)" | "Error %";
type AssetFilter = "portfolios" | "parks" | "assets" | "technology";

interface ErrorStats {
    model: string;
    unit: string;
    bias: number;
    mae: number;
    rmse: number;
    nmae: number;
    nrmse: number;
    capacity: number;
    count: number;
}

interface AssetErrorStats {
    asset: string;
    unit: string;
    bias: number;
    mae: number;
    rmse: number;
    nmae: number;
    nrmse: number;
    capacity: number;
    count: number;
}

const getObsFieldValue = (field: ChartField, obs: Observation): number => {
    switch (field) {
        case "Power (KW)":
            return obs.power!;
        case "Humidity %":
            return obs.humidity!;
        case "Irradiance (W⋅m−2)":
            return obs.irradiance!;
        case "Temperature (°C)":
            return obs.temperature!;
        case "Wind speed (m/sec)":
            return obs.windSpeed!;
        case "Pressure (hPa)":
            return obs.pressure!;
    }

    return 0;
}

const getForecastFieldValue = (field: ChartField, forecast: AssetForecast): number => {
    switch (field) {
        case "Power (KW)":
            return forecast.power;
        case "Humidity %":
            return forecast.relativeHumidity;
        case "Irradiance (W⋅m−2)":
            return forecast.radiation;
        case "Temperature (°C)":
            return forecast.temperature2m;
        case "Wind speed (m/sec)":
            return forecast.windSpeed[0].value;
        case "Pressure (hPa)":
            return forecast.surfacePressure;
    }

    return 0;
}

const getUnit = (field: ChartField): string => {
    switch (field) {
        case "Power (KW)":
            return "KW";
        case "Humidity %":
            return "%";
        case "Irradiance (W⋅m−2)":
            return "W⋅m−2";
        case "Temperature (°C)":
            return "°C";
        case "Wind speed (m/sec)":
            return "m/sec";
        case "Pressure (hPa)":
            return "hPa";
        case "Error %":
            return "%";
    }

    return "";
}

let observations: Array<{ asset: Asset | undefined, observations: Array<Observation> }>;
let forecasts: Array<{ asset: Asset | undefined, forecasts: Array<AssetForecast>, model: string }>;
let minuteInterval = 60;
let dateFrom: Date;
let dateTo: Date;

const Charts: React.FC = props => {
    const [assetFilter, setAssetFilter] = useState<AssetFilter>("assets");
    const [assets, setAssets] = useState<Array<Asset>>([]);
    const [parks, setParks] = useState<Array<Park>>([]);
    const [portfolios, setPortfolios] = useState<Array<Portfolio>>([]);
    const [domains, setDomains] = useState<Array<ForecastDomain>>([]);
    const [loading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [chartData, setChartData] = useState<{ field: ChartField, data: Array<{ x: Date, y: number, category: string }> } | null>(null);
    const [errorStats, setErrorStats] = useState<Array<ErrorStats> | null>(null);
    const [errorStatsPerAsset, setErrorStatsPerAsset] = useState<Array<AssetErrorStats> | null>(null);
    const [chartType, setChartType] = useState(0);
    const context = useContext(AppContext);
    const [form] = useForm();

    const errorStatsPerModelColumns: ColumnsType<ErrorStats> = [
        {
            title: "Model",
            dataIndex: "model",
            sorter: (a, b) => a.model.localeCompare(b.model),
            defaultSortOrder: 'ascend'
        },
        {
            title: "BIAS",
            dataIndex: "bias",
            render: (text, record) => `${record.bias.toFixed(2)} ${record.unit}`,
            sorter: (a, b) => a.bias - b.bias,
        },
        {
            title: "MAE",
            dataIndex: "mae",
            render: (text, record) => `${record.mae.toFixed(2)} ${record.unit}`,
            sorter: (a, b) => a.mae - b.mae,
        },
        {
            title: "RMSE",
            dataIndex: "rmse",
            sorter: (a, b) => a.rmse - b.rmse,
            render: (text, record) => isNaN(record.rmse) ? "-" : `${record.rmse.toFixed(2)} ${record.unit}`,
        },
        {
            title: "NMAE",
            dataIndex: "nmae",
            sorter: (a, b) => a.nmae - b.nmae,
            render: (text, record) => isNaN(record.nmae) ? "-" : `${record.nmae.toFixed(2)}%`,
        },
        {
            title: "NRMSE",
            dataIndex: "nrmse",
            sorter: (a, b) => a.nrmse - b.nrmse,
            render: (text, record) => isNaN(record.nrmse) ? "-" : `${record.nrmse.toFixed(2)}%`,
        },
    ];

    const errorStatsPerAssetColumns: ColumnsType<AssetErrorStats> = [
        {
            title: "Asset",
            dataIndex: ["asset"],
            sorter: (a, b) => a.asset.localeCompare(b.asset),
            defaultSortOrder: 'ascend'
        },
        {
            title: "BIAS",
            dataIndex: "bias",
            render: (text, record) => `${record.bias.toFixed(2)} ${record.unit}`,
            sorter: (a, b) => a.bias - b.bias,
        },
        {
            title: "MAE",
            dataIndex: "mae",
            render: (text, record) => `${record.mae.toFixed(2)} ${record.unit}`,
            sorter: (a, b) => a.mae - b.mae,
        },
        {
            title: "RMSE",
            dataIndex: "rmse",
            sorter: (a, b) => a.rmse - b.rmse,
            render: (text, record) => `${record.rmse.toFixed(2)} ${record.unit}`,
        },
        {
            title: "NMAE",
            dataIndex: "nmae",
            sorter: (a, b) => a.nmae - b.nmae,
            render: (text, record) => isNaN(record.nmae) ? "-" : `${record.nmae.toFixed(2)}%`,
        },
        {
            title: "NRMSE",
            dataIndex: "nrmse",
            sorter: (a, b) => a.nrmse - b.nrmse,
            render: (text, record) => isNaN(record.nrmse) ? "-" : `${record.nrmse.toFixed(2)}%`,
        },
    ];

    const selectAllAssets = () => {
        switch (assetFilter) {
            case 'assets':
                form.setFieldsValue({ "assets": assets.map(x => x.id) });
                break;
            case 'parks':
                form.setFieldsValue({ "assets": parks.map(x => x.id) });
                break;
            case 'portfolios':
                form.setFieldsValue({ "assets": portfolios.map(x => x.id) });
                break;
            case 'technology':
                form.setFieldsValue({ "assets": [1, 2, 3] });
                break;
        }
    };

    const formatSetErrorStats = (field: ChartField, stats: Array<ErrorStats>) => {
        stats.forEach(x => {
            if (field === "Power (KW)" &&
                (x.bias > 1000 ||
                    x.mae > 1000 ||
                    x.rmse > 1000)) {
                x.unit = "MW";
                x.bias /= 1000;
                x.mae /= 1000;
                x.rmse /= 1000;
            }
        });

        setErrorStats(stats);
    }

    const getForecastModelTypes = (): React.ReactNode => {
        if (!(context.userInfo?.admin ?? false)) {
            return <Select optionFilterProp="children" placeholder="Forecast source" autoClearSearchValue={false} loading={loading} mode="multiple" allowClear style={{ width: "100%" }}>
                <Option value={1}>Latest</Option>
                <Option value={12345}>Day ahead report</Option>
            </Select>;
        }

        return <Select optionFilterProp="children" placeholder="Forecast source" autoClearSearchValue={false} loading={loading} mode="multiple" allowClear style={{ width: "100%" }}>
            <Option value={1}>Meteogen (hybrid)</Option>
            <Option value={2}>WRF GFS</Option>
            <Option value={3}>WRF ICON</Option>
            <Option value={4}>ECMWF</Option>
            <Option value={5}>ICON</Option>
            <Option value={6}>ERA5</Option>
            <Option value={12345}>Day ahead report</Option>
        </Select>;
    }

    const getForecastModelEnhancements = (): React.ReactNode => {
        return <Select placeholder="Enhancements" style={{ width: "100%" }}>
            <Option value={0}>Default</Option>
            <Option value={1}>Auto regression</Option>
            <Option value={4}>Bias correction</Option>
            <Option value={5}>None</Option>
        </Select>;
    }

    const getForecastSourceOptions = (): React.ReactNode => {
        const isAdmin = context.userInfo?.admin ?? false;
        return (<>
            <Col span={isAdmin ? 12 : 24}>
                <Form.Item
                    name="forecast"
                >
                    {getForecastModelTypes()}
                </Form.Item>
            </Col>
            {isAdmin && <Col span={12}>
                <Form.Item
                    name="enhancements"
                >
                    {getForecastModelEnhancements()}
                </Form.Item>
            </Col>}
        </>);
    }

    const getDomainOptions = (): ReactNode => {
        return <Select>
            <Option value={0}>Best</Option>
            {domains.map(x => <Option key={x.id} value={x.id}>{x.name}</Option>)}
        </Select>;
    }

    const valueToModelString = (value: number): string => {
        switch (value) {
            case 2:
                return "WrfGfs";
            case 3:
                return "WrfIcon";
            case 4:
                return "Ecmwf";
            case 5:
                return "Icon";
            case 6:
                return "Era5Reanalysis";
        }

        return "Meteogen";
    }

    const valueToModelStringForDisplay = (value: number): string => {
        switch (value) {
            case 1:
                return "Meteogen";
            case 2:
                return "WRF GFS";
            case 3:
                return "WRF ICON";
            case 4:
                return "ECMWF";
            case 5:
                return "ICON";
            case 6:
                return "ERA5";
            case 12345:
                return "Day ahead report";
        }

        return "Meteogen";
    }

    const valueToEnhancements = (value: number): number => {
        if (value === undefined) {
            return 0;
        }

        return value;
    }

    const findRecord = (data: Array<any>, field: string, key: any) => {
        let min = 0;
        let max = data.length - 1;

        while (min <= max) {
            let mid = Math.round((min + max) / 2);
            const diff = key.getTime() - data[mid][field].getTime();
            if (diff === 0) {
                return data[mid];
            }
            else if (diff < 0) {
                max = mid - 1;
            }
            else {
                min = mid + 1;
            }
        }

        return null;
    }

    const onChangeChartTypeClick = (e: any) => {
        const ctype = parseInt(e.target.value);
        if (ctype === 0) {
            createSumChart(
                dateFrom,
                dateTo,
                minuteInterval,
                observations,
                forecasts);
        }
        else if (ctype === 5) {
            createErrorSumChart(dateFrom, dateTo, minuteInterval, observations, forecasts);
        }
        else {
            let field: ChartField = "Power (KW)";
            switch (ctype) {
                case 2:
                    field = "Wind speed (m/sec)";
                    break;
                case 3:
                    field = "Irradiance (W⋅m−2)";
                    break;
                case 4:
                    field = "Temperature (°C)";
                    break;
                case 5:
                    field = "Error %";
                    break;
            }

            createChart(
                dateFrom,
                dateTo,
                minuteInterval,
                observations,
                forecasts,
                field);
        }

        setChartType(ctype);
    }

    const onDrawChartClick = async (values: any) => {
        setLoading(true);
        setChartData(null);
        setErrorStats(null);
        setHasError(false);

        const selection = values.assets as Array<any>;

        let assetIds: Array<number> = [];
        switch (assetFilter) {
            case 'assets':
                assetIds = selection;
                break;
            case "parks":
                assetIds = assets.filter(x => selection.indexOf(x.parkId) !== -1).map(x => x.id);
                break;
            case "portfolios":
                assetIds = assets.filter(x => selection.indexOf(x.portfolioId) !== -1).map(x => x.id);
                break;
            case "technology":
                assetIds = assets.filter(x => selection.indexOf(x.type) !== -1).map(x => x.id);
                break;
        }

        observations = [];
        forecasts = [];
        minuteInterval = 10;

        let strInterval = "TenMinutes";
        if (values["step"] === 1) {
            strInterval = "FifteenMinutes";
            minuteInterval = 15;
        }
        else if (values["step"] === 2) {
            strInterval = "Hour";
            minuteInterval = 60;
        }

        const t1 = (values["date-range"]["0"] as moment.Moment).toDate();
        const t2 = (values["date-range"]["1"] as moment.Moment).toDate();

        dateFrom = new Date(t1.getFullYear(), t1.getMonth(), t1.getDate(), t1.getHours(), t1.getMinutes());
        dateTo = new Date(t2.getFullYear(), t2.getMonth(), t2.getDate(), t2.getHours(), t2.getMinutes());

        let mfd: Date | null = null;
        if (values["max-forecast-date"]) {
            mfd = (values["max-forecast-date"] as moment.Moment).toDate();
        }

        try {
            if (values["forecast"] && values["forecast"].indexOf(12345) > -1) {
                const reportResp = await fetch(`/api/Assets/ReportForecast`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json',
                    },
                    body: JSON.stringify({
                        from: moment(dateFrom).format("YYYY-MM-DDTHH:mm:ss"),
                        to: moment(dateTo).format("YYYY-MM-DDTHH:mm:ss"),
                        dayAhead: true,
                        interval: strInterval,
                        assetIds: assetIds
                    })
                });


                if (reportResp.status === 200) {
                    const reportData = await reportResp.json();
                    const keys = Object.keys(reportData);
                    keys.forEach(key => {
                        const assetId = parseInt(key);

                        forecasts.push({
                            asset: assets.find(x => x.id === assetId),
                            forecasts: reportData[key],
                            model: valueToModelStringForDisplay(12345),
                        });

                        forecasts[forecasts.length - 1].forecasts.forEach(x => x.validDate = moment(x.validDate).add(moment(x.validDate).toDate().getTimezoneOffset(), "minutes").toDate());
                    });
                }
                else {
                    setLoading(false);
                    setHasError(true);
                    return;
                }
            }

            for (let i = 0; i < assetIds.length; i++) {
                const response = await fetch(`/api/Assets/${assetIds[i]}/Observations`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json',
                    },
                    body: JSON.stringify({
                        from: moment(dateFrom).format("YYYY-MM-DDTHH:mm:ss"),
                        to: moment(dateTo).format("YYYY-MM-DDTHH:mm:ss"),
                    })
                });

                if (response.status === 200) {
                    observations.push({
                        asset: assets.find(x => x.id === assetIds[i]),
                        observations: await response.json()
                    });

                    observations[observations.length - 1].observations.forEach(x => x.date = moment(x.date).add(moment(x.date).toDate().getTimezoneOffset(), "minutes").toDate());
                }
                else {
                    setLoading(false);
                    setHasError(true);
                    return;
                }

                if (values["forecast"] && values["forecast"].length > 0) {
                    const forecastSources = values["forecast"] as Array<number>;

                    for (let fsi = 0; fsi < forecastSources.length; fsi++) {
                        let fs = forecastSources[fsi];
                        if (fs === 12345) {
                            continue;
                        }

                        const forecastResponse = await fetch(`/api/Assets/${assetIds[i]}/Forecast`, {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-type': 'application/json',
                            },
                            body: JSON.stringify({
                                from: moment(dateFrom).format("YYYY-MM-DDTHH:mm:ss"),
                                to: moment(dateTo).format("YYYY-MM-DDTHH:mm:ss"),
                                maxForecastDate: mfd ? moment(mfd).format("YYYY-MM-DDTHH:mm:ss") : null,
                                interval: strInterval,
                                modelType: valueToModelString(fs),
                                enhancements: valueToEnhancements(values["enhancements"]),
                                domain: values["domain"],
                                run: values["run"],
                                fields: [ForecastFields.Wind, ForecastFields.Power, ForecastFields.Radiation],
                            })
                        });

                        if (forecastResponse.status === 200) {
                            forecasts.push({
                                asset: assets.find(x => x.id === assetIds[i]),
                                forecasts: await forecastResponse.json(),
                                model: valueToModelStringForDisplay(fs),
                            });

                            forecasts[forecasts.length - 1].forecasts.forEach(x => x.validDate = moment(x.validDate).add(moment(x.validDate).toDate().getTimezoneOffset(), "minutes").toDate());
                        }
                        else {
                            setLoading(false);
                            setHasError(true);
                            return;
                        }
                    }
                }
            }
        }
        catch {
            setLoading(false);
            setHasError(true);
            return;
        }

        if (chartType === 0) {
            createSumChart(
                dateFrom,
                dateTo,
                minuteInterval,
                observations,
                forecasts);
        }
        else if (chartType === 5) {
            createErrorSumChart(
                dateFrom,
                dateTo,
                minuteInterval,
                observations,
                forecasts);
        }
        else {
            let field: ChartField = "Power (KW)";
            switch (chartType) {
                case 2:
                    field = "Wind speed (m/sec)";
                    break;
                case 3:
                    field = "Irradiance (W⋅m−2)";
                    break;
                case 4:
                    field = "Temperature (°C)";
                    break;
            }

            createChart(
                dateFrom,
                dateTo,
                minuteInterval,
                observations,
                forecasts,
                field);
        }

        setLoading(false);
    };

    const createSumChart = (from: Date, to: Date, stepMinutes: number, assetsObservations: Array<{ asset: Asset | undefined, observations: Array<Observation> }>, assetsForecasts: Array<{ asset: Asset | undefined, forecasts: Array<AssetForecast>, model: string }>) => {
        let tmpChartData: Array<{ x: Date, y: number, category: string }> = [];
        let d = from;

        let stats: { [key: string]: { maeSum: number, biasSum: number, rmseSum: number, errorCount: number } } = {};
        let capacityDictionary: any = {};

        while (d <= to) {
            let obsSum = 0;
            let forecastSum: any = {};

            let hasObsData = false;
            let hasForData = false;

            for (let i = 0; i < assetsObservations.length; i++) {
                const assetId = assetsObservations[i].asset!.id;

                const obs = findRecord(assetsObservations[i].observations, "date", d) as Observation | null;
                if (obs !== null) {
                    hasObsData = true;
                    obsSum += obs.power!;

                    if (!capacityDictionary.hasOwnProperty(assetId)) {
                        capacityDictionary[assetId] = assetsObservations[i].asset!.capacity;
                    }
                }

                if (assetsForecasts.length > 0) {
                    const assetForecasts = assetsForecasts.filter(x => x.asset!.id === assetId);
                    for (let idx = 0; idx < assetForecasts.length; idx++) {
                        const af = assetForecasts[idx];
                        const fPower = findRecord(af.forecasts, "validDate", d) as AssetForecast | null;
                        if (fPower !== null) {
                            hasForData = true;
                            if (!forecastSum.hasOwnProperty(af.model)) {
                                forecastSum[af.model] = 0;
                            }

                            forecastSum[af.model] += fPower.power;
                        }
                    };
                }
            }

            if (hasObsData) {
                tmpChartData.push({
                    x: d,
                    y: obsSum,
                    category: "Power"
                });
            }

            if (hasForData) {
                const keys = Object.keys(forecastSum);
                for (let keyIdx = 0; keyIdx < keys.length; keyIdx++) {
                    const m = keys[keyIdx];
                    tmpChartData.push({
                        x: d,
                        y: forecastSum[m],
                        category: `${m} Forecast`
                    });

                    if (hasObsData) {
                        const diff = forecastSum[m] - obsSum;
                        if (!stats[m]) {
                            stats[m] = { biasSum: 0, errorCount: 0, maeSum: 0, rmseSum: 0 };
                        }

                        stats[m].biasSum += diff;
                        stats[m].maeSum += Math.abs(diff);
                        stats[m].rmseSum += diff * diff;
                        stats[m].errorCount++;
                    }
                }
            }

            d = new Date(d.getTime() + (stepMinutes * 60 * 1000));
        }

        setChartData({ field: 'Power (KW)', data: tmpChartData });
        setErrorStatsPerAsset(null);
        setErrorStats(null);

        let capacity = 0;
        Object.keys(capacityDictionary).forEach(x => {
            capacity += capacityDictionary[x];
        });

        let tmpErrorStatsPerModel: Array<ErrorStats> = [];
        Object.keys(stats).forEach(x => {
            let stat = stats[x];
            if (stat.errorCount > 0) {
                tmpErrorStatsPerModel.push({
                    bias: stat.biasSum / stat.errorCount,
                    rmse: Math.sqrt(stat.rmseSum / stat.errorCount),
                    capacity: capacity,
                    count: stat.errorCount,
                    mae: stat.maeSum / stat.errorCount,
                    model: x,
                    nmae: 100 * (stat.maeSum / stat.errorCount) / capacity,
                    nrmse: 100 * Math.sqrt(stat.rmseSum / stat.errorCount) / capacity,
                    unit: "KW"
                });
            }
        });

        tmpErrorStatsPerModel.sort((a, b) => {
            if (a.model > b.model) {
                return 1;
            }
            else if (a.model < b.model) {
                return -1;
            }

            return 0;
        });


        formatSetErrorStats("Power (KW)", tmpErrorStatsPerModel);
    }

    const createErrorSumChart = (from: Date, to: Date, stepMinutes: number, assetsObservations: Array<{ asset: Asset | undefined, observations: Array<Observation> }>, assetsForecasts: Array<{ asset: Asset | undefined, forecasts: Array<AssetForecast>, model: string }>) => {
        let tmpChartData: Array<{ x: Date, y: number, category: string }> = [];
        let d = from;

        let capacityDictionary: any = {};

        let hourObsSum = 0;
        let hourObsCount = 0;

        let stats: { [key: string]: { maeSum: number, biasSum: number, errorCount: number, hourForSum: number, hourForCount: number } } = {};

        for (let i = 0; i < assetsObservations.length; i++) {
            const assetId = assetsObservations[i].asset!.id;

            const obs = findRecord(assetsObservations[i].observations, "date", d) as Observation | null;
            if (obs !== null) {
                if (!capacityDictionary.hasOwnProperty(assetId)) {
                    capacityDictionary[assetId] = assetsObservations[i].asset!.capacity;
                }
            }
        }

        let capacity = 0;
        let assetCount = 0;
        Object.keys(capacityDictionary).forEach(x => {
            capacity += capacityDictionary[x];
            assetCount++;
        });

        while (d <= to) {
            for (let i = 0; i < assetsObservations.length; i++) {
                const assetId = assetsObservations[i].asset!.id;

                const obs = findRecord(assetsObservations[i].observations, "date", d) as Observation | null;
                if (obs !== null) {
                    hourObsSum += obs.power!;
                    hourObsCount++;
                }

                if (assetsForecasts.length > 0) {
                    const assetForecasts = assetsForecasts.filter(x => x.asset!.id === assetId);
                    assetForecasts.forEach(af => {
                        const fPower = findRecord(af.forecasts, "validDate", d) as AssetForecast | null;

                        if (fPower !== null) {
                            if (!stats.hasOwnProperty(af.model)) {
                                stats[af.model] = { biasSum: 0, errorCount: 0, maeSum: 0, hourForCount: 0, hourForSum: 0 };
                            }

                            stats[af.model].hourForSum += fPower.power;
                            stats[af.model].hourForCount++;
                        }
                    });
                }
            }

            if (d.getTime() % (1000 * 3600) === 0) {
                const obsAvg = hourObsCount === 0 ? 0 : hourObsSum / (hourObsCount / assetCount);

                Object.keys(stats).forEach(model => {
                    const forAvg = stats[model].hourForCount === 0 ? 0 : stats[model].hourForSum / (stats[model].hourForCount / assetCount);
                    const diff = forAvg - obsAvg;
                    const mae = Math.abs(diff) / capacity;
                    const bias = diff / capacity;
                    tmpChartData.push({
                        x: d,
                        y: mae * 100,
                        category: `${model} error %`
                    });
                    stats[model].hourForCount = 0;
                    stats[model].hourForSum = 0;

                    stats[model].errorCount++;
                    stats[model].maeSum += mae;
                    stats[model].biasSum += bias;
                });

                hourObsCount = 0;
                hourObsSum = 0;
            }

            d = new Date(d.getTime() + (stepMinutes * 60 * 1000));
        }

        setChartData({ field: 'Error %', data: tmpChartData });
        setErrorStatsPerAsset(null);

        formatSetErrorStats("Error %",
            Object.keys(stats).map(model => {
                return {
                    model: `${model} error %`,
                    unit: "%",
                    mae: 100 * (stats[model].maeSum / stats[model].errorCount),
                    bias: 100 * (stats[model].biasSum / stats[model].errorCount),
                    rmse: NaN,
                    nmae: NaN,
                    nrmse: NaN,
                    count: stats[model].errorCount,
                    capacity: capacity,
                };
            }));
    }

    const createChart = (from: Date, to: Date, stepMinutes: number, observations: Array<{ asset: Asset | undefined, observations: Array<Observation> }>, forecasts: Array<{ asset: Asset | undefined, forecasts: Array<AssetForecast>, model: string }>, field: ChartField) => {
        let tmpChartData: Array<{ x: Date, y: number, category: string }> = [];
        let assetStatsDict: { [key: string]: AssetErrorStats } = {};

        let d = from;

        while (d <= to) {
            for (let i = 0; i < observations.length; i++) {
                const assetId = observations[i].asset!.id;

                const obs = findRecord(observations[i].observations, "date", d) as Observation | null;
                if (obs !== null) {
                    tmpChartData.push({
                        x: d,
                        y: getObsFieldValue(field, obs),
                        category: observations[i].asset!.name
                    });
                }

                if (forecasts.length > 0) {
                    const assetForecasts = forecasts.filter(x => x.asset!.id === assetId);
                    assetForecasts.forEach(af => {
                        const fAsset = findRecord(af.forecasts, "validDate", d) as AssetForecast | null;
                        if (fAsset !== null) {
                            tmpChartData.push({
                                x: d,
                                y: getForecastFieldValue(field, fAsset),
                                category: `${af.model} ${observations[i].asset!.name}`
                            });

                            if (obs !== null) {
                                const key = `${assetId}_${af.model}`;
                                if (!assetStatsDict.hasOwnProperty(key)) {
                                    assetStatsDict[key] = {
                                        unit: getUnit(field),
                                        count: 0,
                                        rmse: 0,
                                        bias: 0,
                                        mae: 0,
                                        nmae: 0,
                                        nrmse: 0,
                                        asset: `${observations[i].asset!.name} ${af.model}`,
                                        capacity: observations[i].asset!.capacity,
                                    };
                                }

                                const diff = getForecastFieldValue(field, fAsset) - getObsFieldValue(field, obs);
                                assetStatsDict[key].bias += diff;
                                assetStatsDict[key].mae += Math.abs(diff);
                                assetStatsDict[key].rmse += diff * diff;
                                assetStatsDict[key].count++;
                            }
                        }
                    });
                }
            }

            d = new Date(d.getTime() + (stepMinutes * 60 * 1000));
        }

        setChartData({ field, data: tmpChartData });
        setErrorStats(null);

        let tmpErrorStatsPerAsset: Array<AssetErrorStats> = [];
        Object.keys(assetStatsDict).forEach(x => {
            let stat = assetStatsDict[x];
            if (stat.count > 0) {
                stat.bias /= stat.count;
                stat.rmse = Math.sqrt(stat.rmse / stat.count);
                stat.mae /= stat.count;
                stat.nmae = field !== 'Power (KW)' || stat.capacity === 0 ? NaN : 100 * stat.mae / stat.capacity;
                stat.nrmse = field !== 'Power (KW)' || stat.capacity === 0 ? NaN : 100 * stat.rmse / stat.capacity;
                tmpErrorStatsPerAsset.push(stat);
            }
        });

        if (tmpErrorStatsPerAsset.length > 0) {
            tmpErrorStatsPerAsset.sort((a, b) => {
                if (a.asset > b.asset) {
                    return 1;
                }
                else if (a.asset < b.asset) {
                    return -1;
                }

                return 0;
            });

            setErrorStatsPerAsset(tmpErrorStatsPerAsset);
        }
        else {
            setErrorStatsPerAsset(null);
        }
    }

    const loadParks = async () => {
        setLoading(true);
        setHasError(false);

        try {
            const response = await fetch('/api/Parks', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            });

            if (response.status === 200) {
                let tmpParks = await response.json() as Array<Park>;
                tmpParks.splice(0, 0, { id: 0, name: "Without park", assets: [] });
                setParks(tmpParks);
            }
            else {
                setHasError(true);
            }
        }
        catch {
            setHasError(true);
        }

        setLoading(false);
    };

    const loadPortfolios = async () => {
        setLoading(true);
        setHasError(false);

        try {
            const response = await fetch('/api/Portfolios', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            });

            if (response.status === 200) {
                let tmp = await response.json() as Array<Portfolio>;
                tmp.splice(0, 0, { id: 0, name: "Without portfolio", assets: [] });
                setPortfolios(tmp);
            }
            else {
                setHasError(true);
            }
        }
        catch {
            setHasError(true);
        }

        setLoading(false);
    };

    const loadAssets = async () => {
        setLoading(true);
        setHasError(false);

        try {
            const response = await fetch('/api/Assets', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            });

            if (response.status === 200) {
                setAssets(await response.json());
            }
            else {
                setHasError(true);
            }
        }
        catch {
            setHasError(true);
        }

        setLoading(false);
    };

    const loadDomains = async () => {
        setLoading(true);
        setHasError(false);

        try {
            const response = await fetch('/api/ForecastDomains', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            });

            if (response.status === 200) {
                setDomains(await response.json());
            }
            else {
                setHasError(true);
            }
        }
        catch {
            setHasError(true);
        }

        setLoading(false);
    };

    const getChartConfig = () => {
        return {
            data: chartData!.data,
            height: 400,
            xField: 'x',
            yField: 'y',
            seriesField: "category",
            yAxis: {
                title: { text: chartData!.field },
            },
            xAxis: {
                type: "time",
            },
            tooltip: {
                formatter: (datum: any) => {
                    return { title: moment(datum.x).format("LLL"), name: datum.category, value: Math.round(datum.y) };
                },
            }
        };
    }

    const handleAssetFilterChange = (value: AssetFilter) => {
        form.setFieldsValue({ "assets": [] });
        setAssetFilter(value);
    }

    const renderAssetsSelect = (): React.ReactNode => {
        if (assetFilter === "parks") {
            if ((parks?.length ?? 0) === 0) {
                return;
            }

            return (<Select optionFilterProp="children" autoClearSearchValue={false} loading={loading} mode="multiple" allowClear style={{ width: "100%" }} placeholder="Select assets">
                {
                    parks.map(p => {
                        return (
                            <Option value={p.id} key={p.id}>{p.name}</Option>
                        );
                    })
                }
            </Select>);
        }
        else if (assetFilter === "portfolios") {
            if ((portfolios?.length ?? 0) === 0) {
                return;
            }

            return (<Select optionFilterProp="children" autoClearSearchValue={false} loading={loading} mode="multiple" allowClear style={{ width: "100%" }} placeholder="Select assets">
                {
                    portfolios.map(p => {
                        return (
                            <Option value={p.id} key={p.id}>{p.name}</Option>
                        );
                    })
                }
            </Select>);
        }
        else if (assetFilter === 'technology') {
            return (<Select optionFilterProp="children" autoClearSearchValue={false} loading={loading} mode="multiple" allowClear style={{ width: "100%" }} placeholder="Select assets">
                <Option value="Other">Other</Option>
                <Option value="WindTurbine">Wind turbines</Option>
                <Option value="PhotovoltaicArray">Photovoltaics</Option>
            </Select>);
        }

        let assetTypes: any = {};
        assets.forEach(x => {
            if (!assetTypes.hasOwnProperty(x.type)) {
                assetTypes[x.type] = [];
            }

            assetTypes[x.type].push(x);
        });

        return (<Select optionFilterProp="children" autoClearSearchValue={false} loading={loading} mode="multiple" allowClear style={{ width: "100%" }} placeholder="Select assets">
            {
                Object.keys(assetTypes).map(k => {
                    return (
                        <OptGroup key={k} label={k}>
                            {(assetTypes[k] as Array<Asset>).map(a => {
                                return (<Option value={a.id} key={a.id}>{a.name}</Option>);
                            })}
                        </OptGroup>
                    );
                })
            }
        </Select>);
    }

    const renderChartOptions = (): React.ReactNode => {
        return (<Form
            form={form}
            name="chart-options"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 8 }}
            layout="horizontal"
            initialValues={{ "max-forecast-date": null, "date-range": { "0": moment().startOf('day').subtract(7, "days"), "1": moment().startOf('day').add(1, "days") }, "step": 2, "domain": 0, "run": "Best", "asset-filter": "assets", "enhancements": 0 }}
            onFinish={onDrawChartClick}
        >
            <h3>Chart options</h3>
            <Form.Item
                name="asset-filter"
                label="Filter by:"
                rules={[{ required: true, message: 'Please at an option.' }]}>
                <Select
                    onChange={handleAssetFilterChange}
                    options={[
                        {
                            value: 'parks',
                            label: 'Parks',
                        },
                        {
                            value: 'portfolios',
                            label: 'Portfolios',
                        },
                        {
                            value: 'assets',
                            label: 'Assets',
                        },
                        {
                            value: 'technology',
                            label: 'Technology',
                        },
                    ]}
                ></Select>
            </Form.Item>

            <Form.Item label="Assets">
                <Input.Group>
                    <Row>
                        <Col span={20}>
                            <Form.Item
                                name="assets"
                                rules={[{ required: true, message: 'Please at least one asset.' }]}>
                                {renderAssetsSelect()}
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Button disabled={loading} onClick={selectAllAssets} type="primary" htmlType="button">Select all</Button>
                        </Col>
                    </Row>
                </Input.Group>
            </Form.Item >

            <Form.Item
                label="Date range"
                name="date-range"
                rules={[{ required: true, message: 'Please select a date range.' }]}
            >
                <RangePicker showTime={true} />
            </Form.Item>

            <Form.Item
                label="Step"
                name="step"
                rules={[{ required: true, message: 'Please select a step.' }]}
            >
                <Select>
                    <Option value={0}>Ten minutes</Option>
                    <Option value={1}>Fifteen minutes</Option>
                    <Option value={2}>Hour</Option>
                </Select>
            </Form.Item>
            <Form.Item
                label="Forecast"
                rules={[{ required: true, message: 'Please select a source.' }]}
            >
                <Input.Group>
                    <Row>
                        {getForecastSourceOptions()}
                    </Row>
                </Input.Group>

            </Form.Item>
            <Form.Item
                label="Domain"
                name="domain"
                hidden={!(context.userInfo?.admin ?? false)}
                rules={[{ required: false }]}
            >
                {getDomainOptions()}
            </Form.Item>
            <Form.Item
                label="Run"
                name="run"
                hidden={!(context.userInfo?.admin ?? false)}
                rules={[{ required: false }]}
            >
                <Select>
                    <Option value={"Best"}>Best</Option>
                    <Option value={"Run00"}>00</Option>
                    <Option value={"Run06"}>06</Option>
                    <Option value={"Run12"}>12</Option>
                    <Option value={"Run18"}>18</Option>
                </Select>
            </Form.Item>
            <Form.Item
                label="Max forecast date"
                name="max-forecast-date"
                hidden={!(context.userInfo?.admin ?? false)}
                rules={[{ required: false }]}
            >
                <DatePicker showTime />
            </Form.Item>
            <Row>
                <Col offset={8} span={4}>
                    <Button disabled={loading} type="primary" htmlType="submit">
                        Draw
                    </Button>
                </Col>
            </Row>
        </Form >);
    }

    useEffect(() => {
        loadParks();
        loadPortfolios();
        loadAssets();
        if ((context.userInfo?.admin ?? false)) {
            loadDomains();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (<>
        {(!loading && assets.length === 0) ? <NoAssets />
            :
            <Card title={renderChartOptions()}>
                {loading && <Spin />}
                {hasError &&
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Result
                                status="error"
                                title="Unable to contact server"
                                subTitle="Please check your connectivity and try again."
                                extra={[
                                    <Button type="primary" key="console" onClick={() => { window.location.reload(); }}>
                                        Retry
                                    </Button>
                                ]}
                            ></Result>
                        </Col>
                    </Row>}
                {
                    (chartData !== null && chartData!.data.length > 0) &&
                    <>
                        <Row>
                            <Col>
                                <Radio.Group value={chartType} onChange={onChangeChartTypeClick}>
                                    <Radio.Button value={0}>Power sum</Radio.Button>
                                    <Radio.Button value={5}>Power forecast error per hour</Radio.Button>
                                    <Radio.Button value={1}>Power per asset</Radio.Button>
                                    <Radio.Button value={2}>Wind per asset</Radio.Button>
                                    <Radio.Button value={3}>Irradiance per asset</Radio.Button>
                                </Radio.Group>
                            </Col>
                        </Row>
                        <Line {...getChartConfig()} />
                        {errorStats !== null && <>
                            {/* <Divider orientation="left">Forecast error</Divider>
                            <Row gutter={16}>
                                {!isNaN(errorStats!.bias) && <Col span={5}>
                                    <Statistic title="BIAS" value={errorStats!.bias.toFixed(2)} suffix={errorStats!.unit} />
                                </Col>}
                                {!isNaN(errorStats!.mae) && <Col span={5}>
                                    <Statistic title="MAE" value={errorStats!.mae.toFixed(2)} suffix={errorStats!.unit} />
                                </Col>}
                                {!isNaN(errorStats!.rmse) && <Col span={5}>
                                    <Statistic title="RMSE" value={errorStats!.rmse.toFixed(2)} suffix={errorStats!.unit} />
                                </Col>}
                                {!isNaN(errorStats!.nmae) && <Col span={5}>
                                    <Statistic title="NMAE (per installed capacity)" value={errorStats!.nmae.toFixed(2)} suffix="%" />
                                </Col>}
                                {!isNaN(errorStats!.nrmse) && <Col span={4}>
                                    <Statistic title="NRMSE (per installed capacity)" value={errorStats!.nrmse.toFixed(2)} suffix="%" />
                                </Col>}
                            </Row> */}
                            <Table pagination={false} rowKey="model" columns={errorStatsPerModelColumns} dataSource={errorStats}></Table>
                        </>}

                        {errorStatsPerAsset !== null &&
                            <Table pagination={false} rowKey="asset" columns={errorStatsPerAssetColumns} dataSource={errorStatsPerAsset}></Table>}
                    </>
                }
            </Card >}
    </>);
}

export default Charts;
