import { Button, Card, Col, Row, Divider } from 'antd';
import React from 'react';
import { RocketOutlined } from '@ant-design/icons';
import gif1 from '../assets/vi_1.gif';
import nwp from '../assets/spec_1.png';
import ml from '../assets/robot.svg';
import wt from '../assets/spec_3.png';
import power from '../assets/ser_img_6.png';
import team from '../assets/iner_team.png';

const WelcomeGr: React.FC = props => {
    return (
        <div className="welcome">
            <Row align="middle" className="row1">
                <Col xs={{ span: 22, offset: 1 }} sm={{ span: 11, offset: 1 }} xl={{ offset: 5, span: 7 }}>
                    <h1>Πρόγνωση καιρού και ενέργειας</h1>
                    <p>Η METEOGEN είναι μια <strong>ελληνική εταιρία</strong> παροχής προγνώσεων καιρού και ενέργειας για αιολικά και φωτοβολταϊκά πάρκα.</p>
                    <p>Παρέχουμε <strong>υπερ-υψηλής χωρικής και χρονικής ανάλυσης προγνώσεις παραγωγής ενέργειας</strong>, καλύπτοντας πλήρως τις ανάγκες των καθημερινών συναλλαγών στις <strong>αγορές ενέργειας</strong> της Ελλάδας και της Ευρώπης, <strong>στις ανταγωνιστικότερες τιμές της αγοράς</strong>.</p>
                </Col>
                <Col xs={{ span: 22, offset: 1 }} sm={{ span: 11, offset: 0 }} xl={{ span: 7, offset: 0 }}>
                    <img alt="" src={gif1} style={{ maxWidth: "100%" }}></img>
                </Col>
            </Row>
            <Row id="services" className="row2">
                <Col span={24}>
                    <h1 style={{ textAlign: "center" }}>Τελευταίας τεχονολογίας προγνωστικά μοντέλα</h1>
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="row2">
                <Col sm={{ span: 22, offset: 1 }} lg={{ span: 11, offset: 1 }} xl={{ offset: 5, span: 7 }}>
                    <Card hoverable={true}>
                        <img alt="" className="card-avatar" src={nwp}></img>
                        <span className="card-title">Αριθμητική πρόγνωση καιρού</span>
                        <p>Τρέχουμε τα πιο σύγχρονα μοντέλα αριθμητικής πρόγνωσης καιρού, τα οποία συνδυάζουμε με τα εγκυρότερα παγκόσμια μοντέλα καιρού ώστε να παράγουμε προγνώσεις πολύ υψηλής ακρίβειας.</p>
                    </Card>
                </Col>
                <Col sm={{ span: 22, offset: 1 }} lg={{ span: 11, offset: 0 }} xl={{ span: 7, offset: 0 }}>
                    <Card hoverable={true}>
                        <img alt="" className="card-avatar" src={ml}></img>
                        <span className="card-title">Μοντέλα machine learning</span>
                        <p>Τα μοντέλα προγνώσεων της METEOGEN χρησιμοποιούν αλγόριθμους machine learning, οι οποίοι λαμβάνουν υπόψη πλήθος παραγόντων που συμβάλουν στη διαμόρφωση της παραγωγής ενέργειας, όπως τη μικρομετεωρολογία κάθε περιοχής, την τοπογραφία, καθώς και τις σχετικές θέσεις των συστημάτων παραγωγής στο χώρο.</p>
                    </Card>
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="row2">
                <Col sm={{ span: 22, offset: 1 }} lg={{ span: 11, offset: 1 }} xl={{ offset: 5, span: 7 }}>
                    <Card hoverable={true}>
                        <img alt="" className="card-avatar" src={wt}></img>
                        <span className="card-title">Υποστήριξη</span>
                        <p>Στη METEOGEN δίνουμε <strong>πολύ μεγάλη σημασία στην υποστήριξη των πελατών</strong>. Εργαζόμαστε διαρκώς μαζί σας για την βελτιστοποίηση των προγνώσεων ενώ πάντοτε υπάρχει διαθέσιμος μηχανικός για την αντιμετώπιση προβλημάτων.</p>
                    </Card>
                </Col>
                <Col sm={{ span: 22, offset: 1 }} lg={{ span: 11, offset: 0 }} xl={{ span: 7, offset: 0 }}>
                    <Card hoverable={true}>
                        <img alt="" className="card-avatar" src={power}></img>
                        <span className="card-title">Πλατφόρμα &amp; API</span>
                        <p>Η METEOGEN διαθέτει ολοκληρωμένη πλατφόρμα διαχείρισης των προγνώσεων καθώς και πλήρες API για διασύνδεση με άλλα υποσυστήματα. Οι προγνώσεις παραδίδονται με διάφορους τρόπους και format, όπως μέσω API, Email ή web και σε Excel καθώς και CSV.</p>
                    </Card>
                </Col>
            </Row>
            <Row id="about" align="middle" gutter={[16, 16]} className="row3">
                <Col sm={{ span: 22, offset: 1 }} lg={{ span: 13, offset: 1 }} xl={{ offset: 5, span: 9 }}>
                    <span className="title"><RocketOutlined /> Δεκαετίες εμπειρίας</span>
                    <p>Η πρόγνωση  μετεωρολογικών δεδομένων αποτελεί αντικείμενο της ομάδας της METEOGEN για <strong>πάνω από 17 χρόνια</strong> καλύπτοντας ανάγκες της ναυσιπλοΐα, της γεωργίας και άλλων πεδίων των ανθρώπινων δραστηριοτήτων που σχετίζονται με τον καιρό.</p>
                    <p>Η ομάδα της METEOGEN επίσης διαχειρίζεται τον ιστότοπο πρόγνωσης καιρού <a href="https://www.k24.net/" rel="noopener noreferrer">k24.net</a>, ένα από τα μεγαλύτερα site πρόγνωσης καιρού στην Ελλάδα.</p>
                </Col>
                <Col sm={{ span: 22, offset: 1 }} lg={{ span: 9, offset: 0 }} xl={{ span: 5, offset: 0 }}>
                    <img alt="" src={team} style={{ maxWidth: "100%" }}></img>
                </Col>
            </Row>
            <Row id="register" style={{ paddingBottom: "20px" }} gutter={[16, 16]} className="row3">
                <Col style={{ textAlign: "center" }} sm={{ span: 22, offset: 1 }} xl={{ offset: 5, span: 14 }}>
                    <Divider></Divider>
                    <h1>Κάνετε εγγραφή και λάβετε δωρεάν πρόγνωση για ένα μήνα για όλα τα έργα σας!</h1>
                    <Button href="/Identity/Account/Register" type="primary" size="large">Εγγραφή</Button>
                </Col>
            </Row>
        </div>
    );
}

export default WelcomeGr;