import { Button, Card, Col, PageHeader, Result, Row, Statistic } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBolt, faChartLine, faCloudSunRain } from '@fortawesome/free-solid-svg-icons';
import { useContext } from 'react';
import { AppContext } from '../state/State';
import { useEffect } from 'react';
import moment from 'moment';
import NoAssets from '../components/NoAssets';

interface DashboardInfo {
    production: number,
    assetsCapacity: number,
    forecastUpdate: Date,
    assetCount: number,
}

const Dashboard: React.FC = props => {
    const [loading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [info, setInfo] = useState<DashboardInfo>({ assetsCapacity: 0, assetCount: 0, production: 0, forecastUpdate: new Date() });

    const context = useContext(AppContext);
    let history = useHistory();

    const fetchInfo = async () => {
        setLoading(true);
        setHasError(false);

        try {
            const response = await fetch('/api/dashboard', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            });

            if (response.status === 200) {
                let tmpInfo: DashboardInfo = await response.json();
                tmpInfo.forecastUpdate = moment(tmpInfo.forecastUpdate).toDate();
                setInfo(tmpInfo);
            }
            else {
                setHasError(true);
            }
        }
        catch {
            setHasError(true);
        }

        setLoading(false);
    };

    useEffect(() => {
        fetchInfo();
    }, []);

    return (
        <>
            <PageHeader
                title={context.userInfo?.organization}
                subTitle="Overview"
                style={{ paddingTop: "0" }}
            />
            {hasError &&
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Result
                            status="error"
                            title="Unable to contact server"
                            subTitle="Please check your connectivity and try again."
                            extra={[
                                <Button type="primary" key="console" onClick={fetchInfo}>
                                    Retry
                                </Button>
                            ]}
                        ></Result>
                    </Col>
                </Row>}

            {!hasError && (loading || info.assetCount > 0) &&
                <Row gutter={[16, 16]}>
                    <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                        <Card loading={loading}>
                            <Statistic title="Last forecast update" value={moment(info.forecastUpdate).format("dd, DD MMM YYYY HH:mm")} prefix={<FontAwesomeIcon icon={faCloudSunRain} />} />
                            <Button onClick={() => history.push("/reports")} type="primary">Get forecasts</Button>
                        </Card>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                        <Card loading={loading}>
                            <Statistic title="Production last 7 days" value={Math.round(info.production * 10) / 10} suffix="GWh" prefix={<FontAwesomeIcon icon={faChartLine} />} />
                            <Button onClick={() => history.push("/charts")} type="primary">View charts</Button>
                        </Card>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                        <Card loading={loading}>
                            <Statistic title="Total assets" value={Math.round(info.assetsCapacity * 10) / 10} suffix="MW" prefix={<FontAwesomeIcon icon={faBolt} />} />
                            <Button onClick={() => history.push("/parks")} type="primary">Manage assets</Button>
                        </Card>
                    </Col>
                </Row>}

            {!hasError && (!loading && info.assetCount === 0) &&
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <NoAssets />
                    </Col>
                </Row>}
        </>
    );
}

export default Dashboard;