export const ForecastFields = {
    Radiation: "Radiation",
    RelativeHumidity: "RelativeHumidity",
    Temperature2m: "Temperature2m",
    SeaLevelPressure: "SeaLevelPressure",
    SurfacePressure: "SurfacePressure",
    Wind: "Wind",
    AirDensity: "AirDensity",
    Power: "Power",
    Energy: "Energy",
    CloudCoverLow: "CloudCoverLow",
    CloudCoverMed: "CloudCoverMed",
    CloudCoverHigh: "CloudCoverHigh",
    SnowCover: "SnowCover",
    Precipitation: "Precipitation"
}
