import './App.css';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from './state/State';
import { Redirect, Route, RouterProps, Switch, useLocation } from 'react-router-dom';
import DashboardLayout from './pages/DashboardLayout';
import Dashboard from './pages/Dashboard';
import LandingLayout from './pages/LandingLayout';
import External from './pages/External';
import Welcome from './pages/Welcome';
import WelcomeGr from './pages/WelcomeGr';
import Terms from './pages/Terms';
import Reports from './pages/Reports';
import Assets from './pages/Assets';
import Asset from './pages/Asset';
import Charts from './pages/Charts';
import Users from './pages/Users';

const App: React.FC<RouterProps> = props => {
    const context = useContext(AppContext);
    const location = useLocation();
    const [ready, setReady] = useState<Boolean>(false);

    // ReactGA.initialize('G-GL0JYE1N38');

    // useEffect(() => {
    //     ReactGA.send({ hitType: "pageview", page: location.pathname, title: document.title });
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [location]);

    useEffect(() => {
        (async () => {
            const response = await fetch('/api/User', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            });

            if (response.status === 200) {
                const info = await response.json();
                context.setLoggedIn(true);
                context.setUserInfo(info);
            }
            else {
                context.setLoggedIn(false);
                context.setUserInfo(null);
            }

            const responseVersion = await fetch('/api/Version', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            });

            if (responseVersion.status === 200) {
                const version = await responseVersion.json();
                context.setVersion(version);
            }

            setReady(true);
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="app">
            {!ready ? <div></div> :
                <Switch>
                    <Route exact path="/" render={() => { return context.loggedIn ? <Redirect to="/dashboard" /> : <Redirect to="/welcome" />; }} />
                    <Route exact path="/welcome" render={() => { return context.loggedIn ? <Redirect to="/dashboard" /> : <LandingLayout><Welcome /></LandingLayout>; }} />
                    <Route exact path="/gr" render={() => { return context.loggedIn ? <Redirect to="/dashboard" /> : <LandingLayout><WelcomeGr /></LandingLayout>; }} />
                    <Route exact path="/terms" render={() => { return context.loggedIn ? <Redirect to="/dashboard" /> : <LandingLayout><Terms /></LandingLayout>; }} />
                    <Route exact path="/dashboard" render={() => { return context.loggedIn ? <DashboardLayout><Dashboard /></DashboardLayout> : <Redirect to={{ pathname: "/external", state: { url: "/Identity/Account/Login?returnUrl=" + encodeURIComponent(location.pathname + location.search) } }} />; }} />
                    <Route exact path="/assets" render={() => { return context.loggedIn ? <DashboardLayout><Assets /></DashboardLayout> : <Redirect to={{ pathname: "/external", state: { url: "/Identity/Account/Login?returnUrl=" + encodeURIComponent(location.pathname + location.search) } }} />; }} />
                    <Route exact path="/assets/create" render={(props) => { return context.loggedIn ? <DashboardLayout><Asset id={""} /></DashboardLayout> : <Redirect to={{ pathname: "/external", state: { url: "/Identity/Account/Login?returnUrl=" + encodeURIComponent(location.pathname + location.search) } }} />; }} />
                    <Route exact path="/assets/:id" render={(props) => { return context.loggedIn ? <DashboardLayout><Asset id={props.match.params.id} /></DashboardLayout> : <Redirect to={{ pathname: "/external", state: { url: "/Identity/Account/Login?returnUrl=" + encodeURIComponent(location.pathname + location.search) } }} />; }} />
                    <Route exact path="/charts" render={() => { return context.loggedIn ? <DashboardLayout><Charts /></DashboardLayout> : <Redirect to={{ pathname: "/external", state: { url: "/Identity/Account/Login?returnUrl=" + encodeURIComponent(location.pathname + location.search) } }} />; }} />
                    <Route exact path="/users" render={() => { return (context.loggedIn && context.userInfo?.admin) ? <DashboardLayout><Users /></DashboardLayout> : <Redirect to={{ pathname: "/external", state: { url: "/Identity/Account/Login?returnUrl=" + encodeURIComponent(location.pathname + location.search) } }} />; }} />
                    <Route exact path="/reports" render={() => { return context.loggedIn ? <DashboardLayout><Reports /></DashboardLayout> : <Redirect to={{ pathname: "/external", state: { url: "/Identity/Account/Login?returnUrl=" + encodeURIComponent(location.pathname + location.search) } }} />; }} />
                    <Route exact path="/external" render={() => { return <External />; }} />
                    <Route path="/:id" render={() => { return <Redirect to="/" />; }} />
                </Switch>
            }
        </div>
    );
}

export default App;