import React from 'react';
import { Layout, Menu, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import { MailOutlined, PhoneOutlined, PushpinOutlined } from '@ant-design/icons';
import { PopupButton } from "react-calendly";
import { HashLink } from 'react-router-hash-link';
import whiteLogo from '../assets/hd_logo.png';

const { Header, Content, Footer } = Layout;

const LandingLayout: React.FC = props => {
    const children = props.children ? props.children : [];
    return (
        <>
            <PopupButton
                url="https://calendly.com/angelos-pappas/30min?hide_event_type_details=1&hide_gdpr_banner=1"
                rootElement={document.getElementById("root")!}
                text="Book a demo"
                className='btn-calendly'
            />
            <Layout className="landing-layout">
                <Header>
                    <Link className="logo" to="/"></Link>
                    <Menu theme="light" style={{ display: "flex", justifyContent: "flex-end" }} mode="horizontal">
                        <Menu.Item><Link to="/welcome">Home</Link></Menu.Item>
                        <Menu.Item><Link to="/dashboard">Account</Link></Menu.Item>
                        <Menu.Item><HashLink to="/welcome#services">Services</HashLink></Menu.Item>
                        <Menu.Item><HashLink to="/welcome#about">About</HashLink></Menu.Item>
                        <Menu.Item><HashLink to="/welcome#contact">Contact</HashLink></Menu.Item>
                        <Menu.Item><a target="_blank" rel="noopener noreferrer" href="/docs/index.html">API docs</a></Menu.Item>
                        <Menu.Item><a target="_blank" rel="noopener noreferrer" href="https://blog.meteogen.com/">Blog</a></Menu.Item>
                    </Menu>
                </Header>
                <Content>
                    <div className="site-layout-content">{children}</div>
                </Content>
                <Footer id="contact">
                    <Row gutter={[16, 16]} align="middle">
                        <Col sm={{ span: 22, offset: 1 }} lg={{ span: 11, offset: 1 }} xl={{ offset: 5, span: 5 }}>
                            <img alt="" src={whiteLogo} style={{ maxWidth: "100%", marginBottom: "10px" }}></img>
                            <p>Weather and energy forecasting services. Global coverage, custom services, unmatched accuracy and very competitive pricing.</p>
                        </Col>
                        <Col sm={{ span: 22, offset: 1 }} lg={{ span: 11, offset: 0 }} xl={{ span: 5, offset: 3 }}>
                            <h2>Contact us</h2>
                            <ul>
                                <li><a href="tel:+302681071653"><PhoneOutlined /> +30 26810 71653</a></li>
                                <li><a href="mailto:info@meteogen.com"><MailOutlined /> info@meteogen.com</a></li>
                                <li><PushpinOutlined /> 3rd km Arta - Antirio, Arta 47100, Greece</li>
                                <li><Link to="/terms">Terms of Use</Link></li>
                            </ul>
                        </Col>
                    </Row>
                </Footer>
            </Layout>
        </>
    );
}

export default LandingLayout;