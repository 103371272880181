import React from 'react';
import { Empty } from "antd";
import { Link } from 'react-router-dom';

const NoAssets: React.FC = props => {
    return (
        <Empty
            description={<div><div>You don't have any assets configured yet. <Link to="/assets">Click here</Link> to add some.</div><div>We will contact you as soon as possible to get you started.</div></div>}>

        </Empty>
    );
}

export default NoAssets;
