import { Col, Row } from 'antd';
import React from 'react';

const Terms: React.FC = props => {
    return (<Row align="middle">

        <Col xs={{ span: 22, offset: 1 }} >

            <h1>TERMS OF USE</h1>
            <p>Welcome to Meteogen, an online platform for weather & energy forecasting. By using this service, you agree to be bound by these Terms of Use, which constitute a legally binding agreement between you and Meteogen. Please read these Terms of Use carefully before using the website.</p>
            <h2>1.	ACCEPTANCE OF TERMS</h2>
            By accessing or using this service, you agree to be bound by these Terms of Use and all applicable laws and regulations.If you do not agree to be bound by these Terms of Use, you may not access or use this website.
            <h2>2.	USE OF THE SERVICE</h2>
            You may use this service only for lawful purposes and in accordance with these Terms of Use.You agree not to use this service:
            <ul>
                <li>In any way that violates any applicable federal, state, local or international law or regulation;</li>
                <li>To engage in any conduct that restricts or inhibits anyone's use or enjoyment of the service, or which, as determined by Meteogen, may harm Meteogen or users of the service or expose them to liability;</li>
                <li>To impersonate or attempt to impersonate Meteogen, a Meteogen employee, another user or any other person or entity;</li>
                <li>To engage in any other conduct that Meteogen determines, in its sole discretion, is harmful to Meteogen or its users.</li>
            </ul>
            <h2>3.	INTELLECTUAL PROPERTY</h2>
            The content, features, and functionality of this service, including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof, are owned by Meteogen, its licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
            <h2>4.	DISCLAIMER OF WARRANTIES</h2>
            THIS SERVICE AND ALL INFORMATION, CONTENT, MATERIALS, PRODUCTS, AND SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THIS SERVICE ARE PROVIDED BY Meteogen ON AN "AS IS" AND "AS AVAILABLE" BASIS, UNLESS OTHERWISE SPECIFIED IN WRITING.Meteogen MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THIS SERVICE OR THE INFORMATION, CONTENT, MATERIALS, PRODUCTS, OR SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THIS SERVICE, UNLESS OTHERWISE SPECIFIED IN WRITING.YOU EXPRESSLY AGREE THAT YOUR USE OF THIS SERVICE IS AT YOUR SOLE RISK.
            <h2>5.	LIMITATION OF LIABILITY</h2>
            IN NO EVENT SHALL Meteogen, ITS OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS, BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER RESULTING FROM ANY(I) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT, (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF OUR SERVICE, (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND / OR ANY AND ALL PERSONAL INFORMATION AND / OR FINANCIAL INFORMATION STORED THEREIN, (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM OUR SERVICE, (V) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH OUR SERVICE BY ANY THIRD PARTY, AND / OR(VI) ANY ERRORS OR OMISSIONS IN ANY CONTENT
        </Col>
    </Row>);
}

export default Terms;

