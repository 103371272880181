import { message, Upload, Button, Col, Form, Row, Select, Steps, UploadProps, Cascader, Divider, InputNumber, Table, Alert, Progress, Result, Modal, Input, notification } from 'antd';
import React, { useEffect, useState } from 'react'
import { Asset } from '../data/Asset'
import { MinusCircleOutlined, PlusCircleOutlined, StepBackwardOutlined, StepForwardOutlined, FileExcelOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import * as XLSX from "xlsx";
import { useForm } from 'antd/es/form/Form';
import moment from 'moment';
import { excelDateToJSDate } from '../Utils';
import { ObservationPost } from '../data/Observation';
import { Link } from 'react-router-dom';
import { DataUploadPreset } from '../data/DataUploadPreset';
import NoAssets from '../components/NoAssets';

const { Step } = Steps;
const { Option } = Select;
const { confirm } = Modal;

type MappedAsset = {
    assetId: string,
    productionColumn: Array<string>,
    windDirectionColumn?: Array<string>,
    windSpeedColumn?: Array<string>,
    irradianceColumn?: Array<string>,
}

type Mapping = {
    dateColumn: Array<string>,
    dateOffsetUtcMinutes: number,
    assetMappings: Array<MappedAsset>,
    productionUnits: "Wh" | "KWh" | "MWh" | "KW" | "MW",
    presetId?: string,
}

type PreviewTableColumn = {
    title: string,
    dataIndex: string,
    key: string,
    children?: Array<PreviewTableColumn>,
}

const AssetData: React.FC<{ assets: Array<Asset> | undefined }> = props => {
    const [currentStep, setCurrentStep] = useState(0);
    const [workBook, setWorkBook] = useState<XLSX.WorkBook | null>(null);
    const [mapping, setMapping] = useState<Mapping | null>(null);
    const [uploading, setUploading] = useState<{ active: boolean, progress: number, processed: number }>({ active: false, progress: 0, processed: 0 });
    const [hasError, setHasError] = useState<boolean>(false);
    const [presets, setPresets] = useState<Array<DataUploadPreset>>([]);
    const [presetSaveForm, setPresetSaveForm] = useState<{ loading: boolean, visible: boolean }>({ loading: false, visible: false });
    const [mappingsForm] = useForm();

    useEffect(() => {
        loadPresets();
    }, []);

    const loadPresets = async () => {
        const presetsResp = await fetch(`/api/DataUploadPresets`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            }
        });

        if (presetsResp.status === 200) {
            setPresets(await presetsResp.json());
        }
    };

    const onSavePresetClick = async (values: any) => {
        setPresetSaveForm({ visible: true, loading: true });

        await fetch(`/api/DataUploadPresets`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                name: values["name"],
                content: JSON.stringify(mapping)
            })
        });

        await loadPresets();

        clearUpload(false);
    };

    const setFormFromPreset = (presetId: string | undefined) => {
        if (!presetId) {
            return;
        }

        const preset = presets.find(x => x.id === parseInt(presetId));
        if (!preset) {
            return;
        }

        mappingsForm.setFieldsValue(JSON.parse(preset.content));
    }

    const getPowerScaleFactor = (): number => {
        if (!mapping || !mapping.assetMappings || !workBook) {
            return 1;
        }

        switch (mapping.productionUnits) {
            case 'KW':
                return 1;
            case 'MW':
                return 1000;
        }

        const dateSheet = workBook!.Sheets[mapping.dateColumn[0]];
        const dateRange = XLSX.utils.decode_range(dateSheet['!ref']!);

        const cell1 = dateSheet[XLSX.utils.encode_cell({ c: parseInt(mapping.dateColumn[1]), r: dateRange.s.r + 1 })];
        const excelDate1 = cell1.v as number;
        const date1 = new Date(excelDateToJSDate(excelDate1).getTime() - (mapping.dateOffsetUtcMinutes * 60 * 1000));

        const cell2 = dateSheet[XLSX.utils.encode_cell({ c: parseInt(mapping.dateColumn[1]), r: dateRange.s.r + 2 })];
        const excelDate2 = cell2.v as number;
        const date2 = new Date(excelDateToJSDate(excelDate2).getTime() - (mapping.dateOffsetUtcMinutes * 60 * 1000));

        const diffMinutes = ((date2.getTime() - date1.getTime()) / 1000) / 60;

        if (diffMinutes > 60 || diffMinutes <= 0) {
            return 1;
        }

        const factor = 60 / diffMinutes;
        let scale = 1;
        if (mapping.productionUnits === 'MWh') {
            scale = 1000;
        }
        else if (mapping.productionUnits === 'Wh') {
            scale = 0.001;
        }

        return factor * scale;
    };

    const getUploadColumns = () => {
        if (!mapping || !mapping.assetMappings) {
            return [];
        }

        let columns: Array<PreviewTableColumn> = [
            {
                title: 'Date UTC',
                dataIndex: 'dateUtc',
                key: 'dateUtc',
            }
        ];

        mapping!.assetMappings.forEach((am, ai) => {
            const asset = props.assets!.find(x => x.id === parseInt(am.assetId))!;
            let children: Array<PreviewTableColumn> = [{
                title: "Power KW",
                dataIndex: `power_${asset.id}`,
                key: `power_${asset.id}`
            }];

            if (am.windSpeedColumn) {
                children.push({
                    title: "Wind speed m/s",
                    dataIndex: `windSpeed_${asset.id}`,
                    key: `windSpeed_${asset.id}`
                });
            }

            if (am.windDirectionColumn) {
                children.push({
                    title: "Wind direction",
                    dataIndex: `windDirection_${asset.id}`,
                    key: `windDirection_${asset.id}`
                });
            }

            if (am.irradianceColumn) {
                children.push({
                    title: "Irradiance",
                    dataIndex: `irradiance_${asset.id}`,
                    key: `irradiance_${asset.id}`
                });
            }

            columns.push({
                title: asset.name,
                dataIndex: "asset",
                key: "asset",
                children: children,
            });
        });

        return columns;
    }

    const getUploadDataPreview = () => {
        if (!mapping || !mapping.assetMappings || !workBook) {
            return [];
        }

        const powerScaleFactor = getPowerScaleFactor();

        const dateSheet = workBook!.Sheets[mapping.dateColumn[0]];
        const dateRange = XLSX.utils.decode_range(dateSheet['!ref']!);

        let data = [];
        for (let i = 1; i < Math.min(101, dateRange.e.r); i++) {
            let cell = dateSheet[XLSX.utils.encode_cell({ c: parseInt(mapping.dateColumn[1]), r: dateRange.s.r + i })];

            const excelDateValue = cell.v as any;
            let excelDate: Date;
            if (typeof excelDateValue === "string") {
                excelDate = moment(excelDateValue, "YYYY-MM-DD HH:mm:ss").toDate();
            }
            else {
                excelDate = excelDateToJSDate(excelDateValue);
            }

            const date = new Date(excelDate.getTime() - (mapping.dateOffsetUtcMinutes * 60 * 1000));
            let row = {
                key: i,
                dateUtc: moment(date).format("YYYY-MM-DD HH:mm:ss")
            };

            data.push(row);

            mapping.assetMappings.forEach((am, ai) => {
                const asset = props.assets!.find(x => x.id === parseInt(am.assetId))!;

                const productionSheet = workBook!.Sheets[am.productionColumn[0]];
                let productionCell = productionSheet[XLSX.utils.encode_cell({ c: parseInt(am.productionColumn[1]), r: dateRange.s.r + i })];

                if (!productionCell) {
                    return;
                }

                Object.assign(row, { [`power_${asset.id}`]: ((productionCell.v as number) * powerScaleFactor).toFixed(3) });

                if (am.windSpeedColumn) {
                    const windSpeedSheet = workBook!.Sheets[am.windSpeedColumn[0]];
                    const windSpeedCell = windSpeedSheet[XLSX.utils.encode_cell({ c: parseInt(am.windSpeedColumn[1]), r: dateRange.s.r + i })];
                    Object.assign(row, { [`windSpeed_${asset.id}`]: windSpeedCell.v as number });
                }

                if (am.windDirectionColumn) {
                    const windDirectionSheet = workBook!.Sheets[am.windDirectionColumn[0]];
                    const windDirectionCell = windDirectionSheet[XLSX.utils.encode_cell({ c: parseInt(am.windDirectionColumn[1]), r: dateRange.s.r + i })];
                    Object.assign(row, { [`windDirection_${asset.id}`]: windDirectionCell.v as number });
                }

                if (am.irradianceColumn) {
                    const irradianceSheet = workBook!.Sheets[am.irradianceColumn[0]];
                    const irradianceCell = irradianceSheet[XLSX.utils.encode_cell({ c: parseInt(am.irradianceColumn[1]), r: dateRange.s.r + i })];
                    Object.assign(row, { [`irradiance_${asset.id}`]: irradianceCell.v as number });
                }
            });
        }

        return data;
    }

    const getHeaderRow = (sheet: XLSX.WorkSheet): Array<{ value: string, label: string }> => {
        let headers = [];
        let range = XLSX.utils.decode_range(sheet['!ref']!);
        let C, R = range.s.r; /* start in the first row */

        /* walk every column in the range */
        for (C = range.s.c; C <= range.e.c; ++C) {
            let cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];

            let hdr = "UNKNOWN " + C;
            if (cell && cell.t) {
                hdr = XLSX.utils.format_cell(cell);
            }

            headers.push({ value: `${C}`, label: hdr });
        }

        return headers;
    }

    const getWorkBookAsCascader = () => {
        if (!workBook || !workBook.SheetNames) {
            return [];
        }

        return workBook!.SheetNames.map(sheetName => {
            return {
                value: sheetName,
                label: sheetName,
                children: getHeaderRow(workBook!.Sheets[sheetName])
            };
        });
    }

    const uploadObservations = async () => {
        if (!mapping || !mapping.assetMappings || !workBook) {
            return;
        }

        setUploading({ active: true, progress: 0, processed: 0 });

        const powerScaleFactor = getPowerScaleFactor();
        const dateSheet = workBook!.Sheets[mapping.dateColumn[0]];
        const dateRange = XLSX.utils.decode_range(dateSheet['!ref']!);

        const totalRecords = mapping.assetMappings.length * dateRange.e.r;
        let processed = 0;

        let data: Array<ObservationPost> = [];
        for (let i = 1; i < dateRange.e.r; i++) {
            let cell = dateSheet[XLSX.utils.encode_cell({ c: parseInt(mapping.dateColumn[1]), r: dateRange.s.r + i })];

            if (!cell) {
                break;
            }

            const excelDateValue = cell.v as any;
            let excelDate: Date;
            if (typeof excelDateValue === "string") {
                excelDate = moment(excelDateValue, "YYYY-MM-DD HH:mm:ss").toDate();
            }
            else {
                excelDate = excelDateToJSDate(excelDateValue);
            }

            const date = new Date(excelDate.getTime() - (mapping.dateOffsetUtcMinutes * 60 * 1000));

            const records = mapping.assetMappings.map((am, ai) => {
                const asset = props.assets!.find(x => x.id === parseInt(am.assetId))!;

                const productionSheet = workBook!.Sheets[am.productionColumn[0]];
                const productionCell = productionSheet[XLSX.utils.encode_cell({ c: parseInt(am.productionColumn[1]), r: dateRange.s.r + i })];

                if (!productionCell) {
                    return null;
                }

                let record: ObservationPost = {
                    assetId: asset.id,
                    date: moment(date).format("YYYY-MM-DDTHH:mm:ss.000") + "Z",
                    power: (productionCell.v as number) * powerScaleFactor
                };

                if (am.windSpeedColumn) {
                    const windSpeedSheet = workBook!.Sheets[am.windSpeedColumn[0]];
                    const windSpeedCell = windSpeedSheet[XLSX.utils.encode_cell({ c: parseInt(am.windSpeedColumn[1]), r: dateRange.s.r + i })];
                    if (windSpeedCell) {
                        record.windSpeed = windSpeedCell.v as number;
                    }
                }

                if (am.windDirectionColumn) {
                    const windDirectionSheet = workBook!.Sheets[am.windDirectionColumn[0]];
                    const windDirectionCell = windDirectionSheet[XLSX.utils.encode_cell({ c: parseInt(am.windDirectionColumn[1]), r: dateRange.s.r + i })];
                    if (windDirectionCell) {
                        record.windDirection = windDirectionCell.v as number;
                    }
                }

                if (am.irradianceColumn) {
                    const irradianceSheet = workBook!.Sheets[am.irradianceColumn[0]];
                    const irradianceCell = irradianceSheet[XLSX.utils.encode_cell({ c: parseInt(am.irradianceColumn[1]), r: dateRange.s.r + i })];
                    if (irradianceCell) {
                        record.irradiance = irradianceCell.v as number;
                    }
                }

                return record;
            });

            for (let i = 0; i < records.length; i++) {
                const r = records[i];
                if (r !== null && r !== undefined) {
                    data.push(r);
                }
            }

            if (data.length >= 500) {
                const uploadPartResponse = await fetch(`/api/Assets/Observations`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json',
                    },
                    body: JSON.stringify(data)
                });

                if (uploadPartResponse.status !== 204) {
                    notification.error({
                        message: 'Uploading observations failed',
                        description: `Between rows ${i - 500} and ${i}:\r\n${await uploadPartResponse.text()}`,
                    });
                }
                else {
                    processed += data.length;
                }

                data = [];
                setUploading({ active: true, progress: Math.min(100, Math.round((processed * 100) / totalRecords)), processed: processed });
            }
        }

        if (data.length > 0) {
            const uploadPartResponse = await fetch(`/api/Assets/Observations`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(data)
            });

            if (uploadPartResponse.status !== 204) {
                notification.error({
                    message: 'Uploading observations failed',
                    description: `At the last ${data.length} rows:\r\n${await uploadPartResponse.text()}`,
                });
            }
            else {
                processed += data.length;
            }
        }

        setUploading({ active: true, progress: 100, processed: processed });
    };

    const clearUpload = async (askSavePreset: boolean) => {
        if (askSavePreset && !mapping?.presetId) {
            confirm({
                title: 'Save preset?',
                icon: <ExclamationCircleOutlined />,
                content: 'You can save this configuration for future use.',
                onOk() {
                    setPresetSaveForm({ loading: false, visible: true });
                },
                onCancel() {
                    clearUpload(false);
                },
            });

            return;
        }

        if (askSavePreset && mapping?.presetId) {
            const preset = presets.find(x => x.id === parseInt(mapping?.presetId ?? "-1"));
            if (preset) {
                await fetch(`/api/DataUploadPresets/${preset.id}`, {
                    method: 'PUT',
                    headers: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json',
                    },
                    body: JSON.stringify({
                        id: preset.id,
                        name: preset.name,
                        content: JSON.stringify(mapping)
                    })
                });

                await loadPresets();
            }
        }

        setHasError(false);
        setPresetSaveForm({ loading: false, visible: false });
        setUploading({ active: false, progress: 0, processed: 0 });
        setWorkBook(null);
        setMapping(null);
        setCurrentStep(0);
        setHasError(false);
        mappingsForm.resetFields();
    }

    const fileProps: UploadProps = {
        accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        onChange: (info: any) => {
            if (info.fileList.length === 0) {
                return;
            }

            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;

            reader.onload = e => {
                const bstr = e.target?.result;
                const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
                setWorkBook(wb);
            };

            reader.onerror = (e) => {
                console.log(e);
            }

            if (rABS) {
                reader.readAsBinaryString(info.file as File);
            }
            else {
                reader.readAsArrayBuffer(info.file as File);
            }
        },
        beforeUpload: (file: any) => {
            return false;
        },
        multiple: false,
        maxCount: 1,
    };

    const steps = [
        {
            title: 'Select a file',
            content: <Row>
                <Col lg={12} xs={24}>
                    <p>In this section you can upload observation data for multiple assets, so that we can build ML forecast models for each one of them. This way the forecasts become much more accurate.</p>
                    <Upload {...fileProps}>
                        <Button icon={<FileExcelOutlined />}>Select an Excel file</Button>
                    </Upload>
                </Col>
            </Row>,
        },
        {
            title: 'Map data',
            content: <Form
                name="map-data"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 8 }}
                layout="horizontal"
                autoComplete="off"
                form={mappingsForm}
            >
                {presets.length > 0 && <Form.Item
                    label="Load preset"
                    name={['presetId']}
                >
                    <Select allowClear onChange={setFormFromPreset}>
                        {presets.map(x => { return <Option key={x.id}>{x.name}</Option>; })}
                    </Select>
                </Form.Item>}

                <Form.Item
                    label="Date column"
                    name={['dateColumn']}
                    rules={[{ required: true, message: 'Please select the date column.' }]}
                >
                    <Cascader options={getWorkBookAsCascader()} placeholder="Date column" />
                </Form.Item>

                <Form.Item
                    label="UTC Timezone offset"
                    name={['dateOffsetUtcMinutes']}
                    help="Timezone offset in minutes from UTC, e.g. for CET it should be 60."
                    rules={[{ required: true, message: 'Please enter the data timezone offset from UTC, in minutes.' }]}
                >
                    <InputNumber placeholder="e.g. 60" controls={false} />
                </Form.Item>

                <Form.Item
                    label="Production units"
                    name={['productionUnits']}
                    help="Select the appropriate unit depending on the contents of the selected file."
                    rules={[{ required: true, message: 'Please select the production unit in the file.' }]}
                >
                    <Select>
                        <Option key="MWh">MWh (Energy)</Option>
                        <Option key="KWh">KWh (Energy)</Option>
                        <Option key="Wh">Wh (Energy)</Option>
                        <Option key="MW">MW (Power)</Option>
                        <Option key="KW">KW (Power)</Option>
                    </Select>
                </Form.Item>
                <Divider></Divider>

                <Form.List name="assetMappings">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <React.Fragment key={key}>
                                    <Form.Item
                                        {...restField}
                                        label="Asset"
                                        name={[name, 'assetId']}
                                        rules={[{ required: true, message: 'Please select an asset.' }]}
                                    >
                                        <Select showSearch={true} optionFilterProp="children" autoClearSearchValue={false} allowClear>
                                            {props.assets!.map((asset) => { return <Option key={asset.id}>{asset.name}</Option>; })}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        {...restField}
                                        label="Production column"
                                        name={[name, 'productionColumn']}
                                        rules={[{ required: true, message: 'Please select the production column.' }]}
                                    >
                                        <Cascader options={getWorkBookAsCascader()} placeholder="Production column" />
                                    </Form.Item>

                                    <Form.Item
                                        {...restField}
                                        label="Wind speed column"
                                        name={[name, 'windSpeedColumn']}
                                    >
                                        <Cascader options={getWorkBookAsCascader()} placeholder="Wind speed column" />
                                    </Form.Item>

                                    <Form.Item
                                        {...restField}
                                        label="Wind direction column"
                                        name={[name, 'windDirectionColumn']}
                                    >
                                        <Cascader options={getWorkBookAsCascader()} placeholder="Wind speed column" />
                                    </Form.Item>

                                    <Form.Item
                                        {...restField}
                                        label="Irradiance column"
                                        name={[name, 'irradianceColumn']}
                                    >
                                        <Cascader options={getWorkBookAsCascader()} placeholder="Irradiance column" />
                                    </Form.Item>
                                    <Row>
                                        <Col lg={{ offset: 4, span: 8 }} span={24}>
                                            <Button type="dashed" danger onClick={() => remove(name)} block icon={<MinusCircleOutlined />}>
                                                Remove mapping
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Divider></Divider>
                                </React.Fragment>))}
                            <Row>
                                <Col lg={{ offset: 4, span: 8 }} span={24}>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusCircleOutlined />}>
                                        Add mapping
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    )}
                </Form.List>
            </Form>,
        },
        {
            title: 'Upload',
            content: <>
                <Alert message={<><h3>WARNING!</h3><p>This will overwrite existing observations for the same dates and assets.</p><p>Please validate the data before uploading. Posting wrong values will severely impact the forecasting procedure.</p><ul><li>Dates will be converted to <strong>UTC</strong>.</li><li>Production will be converted to <strong>power (KW)</strong>, make sure you selected the appropriate input unit.</li></ul><p>If the data below doesn't look correct, go to the previous steps and adjust the dates and power conversion accordingly or fix them in Excel and upload a new file.</p></>} type="warning" />
                <Table bordered size="middle" dataSource={getUploadDataPreview()} columns={getUploadColumns()} />
            </>,
        },
    ];

    const next = async () => {
        if (currentStep === 1) {
            mappingsForm.validateFields().then(values => {
                const mapping = values as Mapping;
                if (!mapping.assetMappings || mapping.assetMappings.length === 0) {
                    message.error("Please add at least one mapping");
                    return;
                }

                setMapping(mapping);
                setCurrentStep(currentStep + 1);
            }).catch(e => {

            });

            return;
        }

        setCurrentStep(currentStep + 1);
    };

    const prev = () => {
        setCurrentStep(currentStep - 1);
    };

    const renderPage = () => {
        if (props.assets?.length === 0) {
            return <NoAssets></NoAssets>
        }

        return (<>
            <Row>
                <Col lg={12} xs={24}>
                    <Steps current={currentStep}>
                        {steps.map(item => (
                            <Step key={item.title} title={item.title} />
                        ))}
                    </Steps>
                </Col>
            </Row>
            <div className="steps-content">{steps[currentStep].content}</div>
            <Row>
                <Col lg={12} xs={24}>
                    {currentStep > 0 && (
                        <Button icon={<StepBackwardOutlined></StepBackwardOutlined>} style={{ marginRight: '8px' }} onClick={() => prev()}>
                            Previous
                        </Button>
                    )}
                    {((currentStep === 0 && workBook !== null) || (currentStep === 1)) && (
                        <Button type="primary" onClick={() => next()}>
                            Next <StepForwardOutlined></StepForwardOutlined>
                        </Button>
                    )}
                    {currentStep === steps.length - 1 && (
                        <Button type="primary" onClick={async () => { try { await uploadObservations(); } catch (e) { console.log(e); setHasError(true); } }}>
                            Upload
                        </Button>
                    )}
                </Col>
            </Row>
        </>);
    }

    return (<>
        {hasError && <Row gutter={[16, 16]}>
            <Col span={24}>
                <Result
                    status="error"
                    title="Error uploading data"
                    subTitle="An error occurred while processing the file. Please contact support."
                    extra={[
                        <Button type="primary" key="console" onClick={() => clearUpload(false)}>
                            Retry
                        </Button>
                    ]}
                ></Result>
            </Col>
        </Row>}
        {!hasError && (uploading.active ?
            (uploading.progress === 100 ?
                <Result
                    status="success"
                    title={`Successfully uploaded ${uploading.processed} records!.`}
                    subTitle={<>You can inspect them in the <Link to="/charts">Charts</Link> section. The system will start processing them soon.</>}
                    extra={[
                        <Button type="primary" key="console" onClick={() => clearUpload(true)}>
                            Done
                        </Button>
                    ]}
                />
                :
                <>
                    <h3>Please wait while the data is being processed.</h3>
                    <Progress percent={uploading.progress} />
                </>) :
            renderPage())}
        <Modal
            title="Save preset"
            visible={presetSaveForm.visible}
            confirmLoading={presetSaveForm.loading}
            destroyOnClose={true}
            footer={null}
        >
            <Form
                name="save-preset"
                wrapperCol={{ span: 24 }}
                layout="horizontal"
                initialValues={{ "name": "" }}
                onFinish={onSavePresetClick}
            >
                <Form.Item
                    name="name"
                    rules={[{ required: true, message: 'Please enter the name of the preset.' }]}
                >
                    <Input placeholder="E.g. preset 1" />
                </Form.Item>
                <Row>
                    <Col span={24} style={{ textAlign: "right" }}>
                        <Button loading={presetSaveForm.loading} type="primary" htmlType="submit">
                            Save
                        </Button>
                        <Button disabled={presetSaveForm.loading} htmlType="button" onClick={() => { clearUpload(false); }}>
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    </>);
}

export default AssetData;
