export const excelDateToJSDate = (serial: number): Date => {
    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;
    const date_info = new Date(utc_value * 1000);

    const fractional_day = serial - Math.floor(serial) + 0.0000001;

    let total_seconds = Math.floor(86400 * fractional_day);

    const seconds = total_seconds % 60;

    total_seconds -= seconds;

    const hours = Math.floor(total_seconds / (60 * 60));
    const minutes = Math.floor(total_seconds / 60) % 60;

    // original code
    // return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);

    // we round to the closest 5' instead
    let d = (new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, 0)).getTime();
    const fiveMinutes = 1000 * 60 * 5;
    return new Date(Math.round(d / fiveMinutes) * fiveMinutes);
}
