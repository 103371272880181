import React, { useEffect, useState } from 'react'
import { Button, Col, Empty, PageHeader, Result, Row, Select, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { CloseOutlined, EditOutlined, SaveOutlined, UserOutlined } from '@ant-design/icons';

const { Option } = Select;

interface User {
    id: string;
    email: string;
    userName: string;
    organizationId: number;
    organization: string;
}

interface Organization {
    id: number;
    name: string;
}

interface EditableCellProps {
    dataIndex: string;
    title: string;
    inputType: string;
    record: any;
    dataSource: Array<Organization>,
    children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
    dataIndex,
    record,
    dataSource,
}) => {
    const [editing, setEditing] = useState(false);
    const [value, setValue] = useState(record[dataIndex]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e);
    };

    const edit = () => {
        setEditing(true);
    };

    const cancel = () => {
        setValue(record[dataIndex]);
        setEditing(false);
    };

    const save = async () => {
        record.organizationId = value;
        record.organization = dataSource.find(x => x.id === value)?.name;

        await fetch(`/api/Admin/User/${record.id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                organizationId: record.organizationId
            })
        });

        setEditing(false);
    };

    return (
        <div>
            {editing ? (
                <Row wrap={false}>
                    <Col flex="auto">
                        <Select
                            defaultValue={value}
                            value={value}
                            style={{ width: "100%" }}
                            onChange={handleChange}
                        >
                            {dataSource.map(x => <Option key={`${record.id}_${x.id}`} value={x.id}>{x.name}</Option>)}
                        </Select>
                    </Col>
                    <Col flex="none">
                        <Space align='end' style={{ width: "100%", justifyContent: "end" }}>
                            <Button type="primary" title='Save' icon={<SaveOutlined></SaveOutlined>} onClick={save}></Button>
                            <Button type="link" title='Cancel' icon={<CloseOutlined></CloseOutlined>} onClick={cancel}></Button>
                        </Space>
                    </Col>
                </Row>
            ) : (
                <Row wrap={false}>
                    <Col flex="auto">
                        {record.organization}
                    </Col>
                    <Col flex="none">
                        <Space align='end' style={{ width: "100%", justifyContent: "end" }}>
                            <Button title='Edit' icon={<EditOutlined></EditOutlined>} onClick={edit} type="primary" ></Button>
                        </Space>
                    </Col>
                </Row>
            )}
        </div>
    );
};

const Users: React.FC = props => {
    const [loading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [userList, setUserList] = useState<Array<User>>([]);
    const [organizations, setOrganizations] = useState<Array<Organization>>([]);

    const fetchList = async () => {
        setLoading(true);
        setHasError(false);

        try {
            let response = await fetch(`/api/Admin/Users`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                }
            });

            if (response.status !== 200) {
                setLoading(false);
                setHasError(true);
                return
            }

            setUserList(await response.json());

            response = await fetch(`/api/Admin/Organizations`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                }
            });

            if (response.status !== 200) {
                setLoading(false);
                setHasError(true);
                return
            }

            setOrganizations(await response.json());
        }
        catch (error) {
            setHasError(true);
        }

        setLoading(false);
    };

    const columns: ColumnsType<User> = [
        {
            title: "Username",
            dataIndex: "userName",
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.userName.toLocaleLowerCase().localeCompare(b.userName.toLocaleLowerCase()),
        },
        {
            title: "Organization",
            dataIndex: "organizationId",
            sorter: (a, b) => a.organization.toLocaleLowerCase().localeCompare(b.organization.toLocaleLowerCase()),
            render: (text: string, record: User) => {
                return (
                    <EditableCell
                        dataIndex="organizationId"
                        title="Organization"
                        inputType="dropdown"
                        record={record}
                        dataSource={organizations}
                    >
                        {record.organization}
                    </EditableCell>
                );
            }
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (text, record) => {
                return (
                    <a href={`/api/admin/impersonate/${record.id}`}><Button title='Impersonate' icon={<UserOutlined></UserOutlined>} type="link" ></Button></a>
                );
            }
        },
    ];

    useEffect(() => {
        fetchList();
    }, []);

    return (
        <>
            <PageHeader
                title="Admin"
                subTitle="Users"
                style={{ paddingTop: "0" }}
            />
            {hasError &&
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Result
                            status="error"
                            title="Unable to contact server"
                            subTitle="Please check your connectivity and try again."
                            extra={[
                                <Button type="primary" key="console" onClick={fetchList}>
                                    Retry
                                </Button>
                            ]}
                        ></Result>
                    </Col>
                </Row>}

            {!hasError && (loading || userList.length > 0) &&
                <Table rowKey="id" loading={loading} columns={columns} dataSource={userList}></Table>}

            {!hasError && !loading && userList.length === 0 &&
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Empty description="No users found"></Empty>
                    </Col>
                </Row>}
        </>
    );
}

export default Users;
