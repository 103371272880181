import { Button, Col, Form, Input, InputNumber, Modal, Result, Row, Select, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react'
import { Asset as AssetEntity, AssetProps, AssetType } from '../data/Asset'
import { Park } from '../data/Park';
import { Portfolio } from '../data/Portfolio';
import { PlusCircleFilled } from '@ant-design/icons';
import { useHistory } from 'react-router';

const { Option } = Select;

const Asset: React.FC<{ id: string }> = props => {
    const [portfolios, setPortfolios] = useState<Array<Portfolio>>([]);
    const [parks, setParks] = useState<Array<Park>>([]);
    const [asset, setAsset] = useState<AssetEntity | null>(null);
    const [loadingState, setLoadingState] = useState<{ loading: boolean, hasError: boolean }>({ loading: false, hasError: false });
    const [parkModalState, setParkModalState] = useState<{ visible: boolean, loading: boolean }>({ loading: false, visible: false });
    const [portfolioModalState, setPortfolioModalState] = useState<{ visible: boolean, loading: boolean }>({ loading: false, visible: false });
    const [assetType, setAssetType] = useState<AssetType>("Other");
    const [assetProps, setAssetProps] = useState<AssetProps>({});
    const history = useHistory();

    const renderParksSelect = () => {
        return (<Select>
            <Option key={0} value={0}>No park</Option>
            {parks.map(x => <Option key={x.id} value={x.id}>{x.name}</Option>)}
        </Select>);
    }

    const renderPortfoliosSelect = () => {
        return (<Select>
            <Option key={0} value={0}>No portfolio</Option>
            {portfolios.map(x => <Option key={x.id} value={x.id}>{x.name}</Option>)}
        </Select>);
    }

    const loadAsset = async () => {
        const assetResp = await fetch(`/api/Assets/${props.id}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            }
        });

        if (assetResp.status === 200) {
            const a = await assetResp.json() as AssetEntity;
            setAsset(a);
            setAssetType(a.type);
            if (a.assetProps !== null) {
                const props = JSON.parse(a.assetProps);
                setAssetProps(props);
            }

            document.title = `Edit ${a.name}`;
            return true;
        }

        return false;
    };

    const loadParks = async () => {
        const parksResp = await fetch(`/api/Parks`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            }
        });

        if (parksResp.status === 200) {
            setParks(await parksResp.json());
            return true;
        }

        return false;
    };

    const loadPortfolios = async () => {
        const portfoliosResp = await fetch(`/api/Portfolios`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            }
        });

        if (portfoliosResp.status === 200) {
            setPortfolios(await portfoliosResp.json());
            return true;
        }

        return false;
    };

    const loadData = async () => {
        setLoadingState({ loading: true, hasError: false });

        if (props.id !== "") {
            const d1 = await loadAsset();
            if (!d1) {
                setLoadingState({ loading: false, hasError: true });
                return;
            }
        }

        const d2 = await loadParks();
        if (!d2) {
            setLoadingState({ loading: false, hasError: true });
            return;
        }

        const d3 = await loadPortfolios();
        if (!d3) {
            setLoadingState({ loading: false, hasError: true });
            return;
        }

        setLoadingState({ loading: false, hasError: false });
    };

    const onSaveParkClick = async (values: any) => {
        setParkModalState({ visible: true, loading: true });

        const parkResp = await fetch(`/api/Parks`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                name: values["name"]
            })
        });

        if (parkResp.status === 201) {
            await loadParks();
            setParkModalState({ loading: false, visible: false });
            return;
        }

        setParkModalState({ loading: false, visible: false });
        setLoadingState({ loading: false, hasError: true });
    };

    const onSavePortfolioClick = async (values: any) => {
        setPortfolioModalState({ visible: true, loading: true });

        const portfolioResp = await fetch(`/api/Portfolios`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                name: values["name"]
            })
        });

        if (portfolioResp.status === 201) {
            await loadPortfolios();
            setPortfolioModalState({ loading: false, visible: false });
            return;
        }

        setPortfolioModalState({ loading: false, visible: false });
        setLoadingState({ loading: false, hasError: true });
    };

    const showAddParkModal = () => {
        setParkModalState({ visible: true, loading: false });
    }

    const showAddPortfolioModal = () => {
        setPortfolioModalState({ visible: true, loading: false });
    }


    const onFieldsChange = (changedFields: any, allFields: any) => {
        setAssetType(allFields[1].value);
    }

    const onSaveClick = async (values: any) => {
        setLoadingState({ loading: true, hasError: false });
        let parkId = values["park-id"];
        if (parkId === undefined || parkId === 0) {
            parkId = null;
        }

        let portfolioId = values["portfolio-id"];
        if (portfolioId === undefined || portfolioId === 0) {
            portfolioId = null;
        }

        const url = props.id === "" ? `/api/Assets` : `/api/Assets/${props.id}`;
        const method = props.id === "" ? "POST" : "PUT";
        const assetId = props.id === "" ? 0 : parseInt(props.id);

        const atype: AssetType = values["type"];
        let aprops: AssetProps = {};
        if (atype === 'WindTurbine') {
            aprops.HubHeight = values["hub-height"];
            aprops.SweptArea = values["swept-area"];
        }
        else if (atype === 'PhotovoltaicArray') {
            aprops.Tilt = values["tilt"];
        }

        const assetResp = await fetch(url, {
            method: method,
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                name: values["name"],
                parkId: parkId,
                portfolioId: portfolioId,
                id: assetId,
                organizationId: 0,
                type: atype,
                longitude: values["longitude"],
                latitude: values["latitude"],
                capacity: values["capacity"],
                AssetProps: `${JSON.stringify(aprops)}`,
            })
        });

        if (assetResp.status >= 400) {
            setLoadingState({ loading: false, hasError: true });
            return;
        }

        history.push("/assets");
    }

    const renderForm = () => {
        return (<Form
            name="create-edit-asset"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 8 }}
            layout="horizontal"
            initialValues={{ "name": asset?.name, "type": asset?.type, "longitude": asset?.longitude, "latitude": asset?.latitude, "capacity": asset?.capacity, "park-id": asset?.parkId, "portfolio-id": asset?.portfolioId, "tilt": assetProps.Tilt, "hub-height": assetProps.HubHeight, "swept-area": assetProps.SweptArea }}
            onFieldsChange={onFieldsChange}
            onFinish={onSaveClick}
        >
            <h3>{props.id === "" ? "Create" : "Edit"} asset</h3>

            <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please enter the name of the asset.' }]}
            >
                <Input placeholder="e.g. Wind Turbine 1" />
            </Form.Item>

            <Form.Item
                label="Type"
                name="type"
                rules={[{ required: true, message: 'Please select the type of the asset.' }]}
            >
                <Select>
                    <Option key={"Other"}>Other</Option>
                    <Option key={"WindTurbine"}>Wind turbine</Option>
                    <Option key={"PhotovoltaicArray"}>Photovoltaic array</Option>
                </Select>
            </Form.Item>

            <Row>
                <Col span={6}>
                    <Form.Item
                        labelCol={{ span: 16 }}
                        label="Longitude"
                        name="longitude"
                        rules={[{ required: true, message: 'Please enter the longitude of the asset.', type: 'number' }]}
                    >
                        <InputNumber placeholder="e.g. 20" controls={false} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        labelCol={{ span: 8 }}
                        label="Latitude"
                        name="latitude"
                        rules={[{ required: true, message: 'Please enter the latitude of the asset.', type: 'number', min: -180, max: 180 }]}
                    >
                        <InputNumber placeholder="e.g. 30" controls={false} />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item
                label="Capacity KW"
                name="capacity"
                rules={[{ required: true, message: 'Please enter the capacity of the asset in KW.', type: 'number', min: 0 }]}
            >
                <InputNumber placeholder="e.g. 1000kW" controls={false} />
            </Form.Item>
            <Form.Item
                hidden={assetType !== 'PhotovoltaicArray'}
                label="Panel tilt in degrees"
                name="tilt"
                rules={[{ type: 'number', min: 0 }]}
            >
                <InputNumber placeholder="e.g. 25" controls={false} />
            </Form.Item>
            <Form.Item
                hidden={assetType !== 'WindTurbine'}
                label="Hub height in meters"
                name="hub-height"
                rules={[{ type: 'number', min: 0 }]}
            >
                <InputNumber placeholder="e.g. 80" controls={false} />
            </Form.Item>
            <Form.Item
                hidden={assetType !== 'WindTurbine'}
                label="Swept area in square meters"
                name="swept-area"
                rules={[{ type: 'number', min: 0 }]}
            >
                <InputNumber placeholder="e.g. 5000" controls={false} />
            </Form.Item>
            <Form.Item label="Park">
                <Row>
                    <Col span={16}>
                        <Form.Item style={{ marginBottom: "0px" }} name="park-id">
                            {renderParksSelect()}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Button block={true} type="primary" icon={<PlusCircleFilled />} onClick={showAddParkModal}>Add park</Button>
                    </Col>
                </Row>
            </Form.Item>

            <Form.Item label="Portfolio">
                <Row>
                    <Col span={16}>
                        <Form.Item style={{ marginBottom: "0px" }} name="portfolio-id">
                            {renderPortfoliosSelect()}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Button block={true} type="primary" icon={<PlusCircleFilled />} onClick={showAddPortfolioModal}>Add portfolio</Button>
                    </Col>
                </Row>
            </Form.Item>
            <Row>
                <Col offset={8} span={4}>
                    <Button disabled={loadingState.loading} type="primary" htmlType="submit">
                        Save
                    </Button>
                    <Button disabled={loadingState.loading} htmlType="button" onClick={() => { history.push("/assets"); }}>
                        Cancel
                    </Button>
                </Col>
            </Row>
        </Form>);
    }

    useEffect(() => {
        loadData();
        if (props.id === "") {
            document.title = `Create asset`;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (<>
        {loadingState.loading && <Skeleton />}
        {!loadingState.loading && !loadingState.hasError && renderForm()}
        {!loadingState.loading && loadingState.hasError && <Row gutter={[16, 16]}>
            <Col span={24}>
                <Result
                    status="error"
                    title="Unable to contact server"
                    subTitle="Please check your connectivity and try again."
                    extra={[
                        <Button type="primary" key="console" onClick={loadData}>
                            Retry
                        </Button>
                    ]}
                ></Result>
            </Col>
        </Row>}
        <Modal
            title="Add park"
            visible={parkModalState.visible}
            confirmLoading={parkModalState.loading}
            destroyOnClose={true}
            footer={null}
        >
            <Form
                name="add-park"
                wrapperCol={{ span: 24 }}
                layout="horizontal"
                initialValues={{ "name": "" }}
                onFinish={onSaveParkClick}
            >
                <Form.Item
                    name="name"
                    rules={[{ required: true, message: 'Please enter the name of the park.' }]}
                >
                    <Input placeholder="E.g. PV park 1" />
                </Form.Item>
                <Row>
                    <Col span={24} style={{ textAlign: "right" }}>
                        <Button loading={parkModalState.loading} type="primary" htmlType="submit">
                            Save
                        </Button>
                        <Button disabled={parkModalState.loading} htmlType="button" onClick={() => { setParkModalState({ loading: false, visible: false }) }}>
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Modal>

        <Modal
            title="Add portfolio"
            visible={portfolioModalState.visible}
            confirmLoading={portfolioModalState.loading}
            destroyOnClose={true}
            footer={null}
        >
            <Form
                name="add-portfolio"
                wrapperCol={{ span: 24 }}
                layout="horizontal"
                initialValues={{ "name": "" }}
                onFinish={onSavePortfolioClick}
            >
                <Form.Item
                    name="name"
                    rules={[{ required: true, message: 'Please enter the name of the portfolio.' }]}
                >
                    <Input placeholder="E.g. Operational" />
                </Form.Item>
                <Row>
                    <Col span={24} style={{ textAlign: "right" }}>
                        <Button loading={portfolioModalState.loading} type="primary" htmlType="submit">
                            Save
                        </Button>
                        <Button disabled={portfolioModalState.loading} htmlType="button" onClick={() => { setPortfolioModalState({ loading: false, visible: false }) }}>
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    </>);
}

export default Asset;
