import React, { SetStateAction, createContext, useState } from 'react';
import { UserInfo } from "../data/User";

export interface AppState {
    loggedIn: boolean,
    setLoggedIn: React.Dispatch<SetStateAction<boolean>>,
    userInfo: UserInfo | null
    setUserInfo: React.Dispatch<SetStateAction<UserInfo | null>>,
    dashboardMenuActiveOption: string
    setDashboardMenuActiveOption: React.Dispatch<SetStateAction<string>>,
    version: Array<number>
    setVersion: React.Dispatch<SetStateAction<Array<number>>>,
};

const initialState: AppState = {
    loggedIn: false,
    setLoggedIn: (action: boolean | ((prevState: boolean) => boolean)) => undefined,
    userInfo: null,
    setUserInfo: (action: (UserInfo | null) | ((prevState: (UserInfo | null)) => (UserInfo | null))) => undefined,
    dashboardMenuActiveOption: "",
    setDashboardMenuActiveOption: (action: string | ((prevState: string) => string)) => undefined,
    version: [1, 0, 0],
    setVersion: (action: Array<number> | ((prevState: Array<number>) => Array<number>)) => undefined,
};

let AppContext = createContext<AppState>(initialState);

// let reducer = (state: AppState, action: ReducerAction): AppState => {
//     switch (action.type) {
//         case "LOGIN": {
//             return {
//                 ...state,
//                 loggedIn: true,
//                 userInfo: action.payload.userInfo
//             }
//         }
//         case "LOGOUT": {
//             return {
//                 ...state,
//                 loggedIn: false,
//                 userInfo: null
//             }
//         }
//         case "DashboardMenuActiveOptionChangedAction": {
//             return {
//                 ...state,
//                 dashboardMenuActiveOption: action.option,
//             }
//         }
//         case "SetVersion": {
//             return {
//                 ...state,
//                 version: action.option,
//             }
//         }
//     }

//     return state;
// };

const AppContextProvider: React.FC = ({ children }) => {
    const [loggedIn, setLoggedIn] = useState<boolean>(false);
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [dashboardMenuActiveOption, setDashboardMenuActiveOption] = useState<string>("");
    const [version, setVersion] = useState<Array<number>>([1, 0, 0]);
    let stateValue: AppState = {
        loggedIn: loggedIn,
        setLoggedIn: setLoggedIn,
        userInfo: userInfo,
        setUserInfo: setUserInfo,
        dashboardMenuActiveOption: dashboardMenuActiveOption,
        setDashboardMenuActiveOption: setDashboardMenuActiveOption,
        version: version,
        setVersion: setVersion,
    }

    return (
        <AppContext.Provider value={stateValue}>{children}</AppContext.Provider>
    );
}

export { AppContext, AppContextProvider };
