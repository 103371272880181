import React from 'react';
import { Layout, Menu, Col, Row } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { AimOutlined, BuildOutlined } from '@ant-design/icons';
import { useContext } from 'react';
import { AppContext } from '../state/State';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartBar, faChartLine, faFileExcel, faUser, faBlog, faBug } from '@fortawesome/free-solid-svg-icons';

const { Header, Content, Footer, Sider } = Layout;

const DashboardLayout: React.FC = props => {
    const children = props.children ? props.children : [];
    const context = useContext(AppContext);
    const [menuCollapsed, setMenuCollapsed] = useState(false);

    const loc = useLocation();
    useEffect(() => {
        const pageTitleSuffix = " - Meteogen";
        switch (loc.pathname) {
            case "/dashboard":
                context.setDashboardMenuActiveOption("dashboard-home");
                document.title = "Dashboard" + pageTitleSuffix;
                break;
            case "/reports":
                context.setDashboardMenuActiveOption("dashboard-reports");
                document.title = "Reports" + pageTitleSuffix;
                break;
            case "/charts":
                context.setDashboardMenuActiveOption("dashboard-charts");
                document.title = "Charts" + pageTitleSuffix;
                break;
            case "/assets":
                context.setDashboardMenuActiveOption("dashboard-assets");
                document.title = "Assets" + pageTitleSuffix;
                break;
            case "/users":
                context.setDashboardMenuActiveOption("dashboard-users");
                document.title = "Users" + pageTitleSuffix;
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loc]);

    return (
        <Layout className="dashboard-layout">
            <Header>
                <Row>
                    <Col span={24}>
                        <Link className="logo" to="/"></Link>
                        <Menu theme="light" style={{ display: "flex", justifyContent: "flex-end" }} mode="horizontal">
                            <Menu.Item key="dashboard"><Link to="/dashboard">Dashboard</Link></Menu.Item>
                            <Menu.Item key="account"><a href="/Identity/Account/Manage">Account</a></Menu.Item>
                            <Menu.Item key="logout"><a href="/Identity/Account/Logout">Logout</a></Menu.Item>
                            <Menu.Item key="apidocs"><a target="_blank" rel="noopener noreferrer" href="/docs/index.html">API docs</a></Menu.Item>
                        </Menu>
                    </Col>
                </Row>
            </Header>
            <Content>
                <Layout>
                    <Sider collapsible collapsed={menuCollapsed} onCollapse={(x) => { setMenuCollapsed(x) }}>
                        <Menu
                            mode="inline"
                            style={{ height: '100%' }}
                            selectedKeys={[context.dashboardMenuActiveOption]}
                        >
                            <Menu.Item icon={<AimOutlined />} title="Dashboard" key="dashboard-home"><Link to="/dashboard">Dashboard</Link></Menu.Item>
                            <Menu.Item icon={<BuildOutlined />} title="Assets" key="dashboard-assets"><Link to="/assets">Assets</Link></Menu.Item>
                            <Menu.Item icon={<FontAwesomeIcon icon={faChartLine} />} title="Charts" key="dashboard-charts"><Link to="/charts">Charts</Link></Menu.Item>
                            <Menu.Item icon={<FontAwesomeIcon icon={faFileExcel} />} title="Reports" key="dashboard-reports"><Link to="/reports">Reports</Link></Menu.Item>
                            {context.userInfo?.grafanaUrl && <Menu.Item icon={<FontAwesomeIcon icon={faChartBar} />} title="Grafana" key="dashboard-grafana"><a rel="noreferrer" target='_blank' href={context.userInfo?.grafanaUrl}>Grafana</a></Menu.Item>}
                            {context.userInfo?.admin && <Menu.Item icon={<FontAwesomeIcon icon={faUser} />} title="Users" key="dashboard-users"><Link to="/users">Users</Link></Menu.Item>}
                            {context.userInfo?.admin && <Menu.Item icon={<FontAwesomeIcon icon={faBlog} />} title="CMS" key="dashboard-cms"><a rel="noreferrer" target='_blank' href="https://blog.meteogen.com/manager">CMS</a></Menu.Item>}
                            {context.userInfo?.admin && <Menu.Item icon={<FontAwesomeIcon icon={faBug} />} title="Elmah" key="dashboard-el,ah"><a rel="noreferrer" target='_blank' href="/elmah">Elmah</a></Menu.Item>}
                        </Menu>
                    </Sider>
                    <Content>
                        <div className="dashboard-layout-content">{children}</div>
                    </Content>
                </Layout>
            </Content>
            <Footer>
                <Row>
                    <Col span={24}>
                        Meteogen {(new Date()).getFullYear()} - {context.version.join(".")}
                    </Col>
                </Row>
            </Footer>
        </Layout>
    );
}

export default DashboardLayout;