import { Button, Card, Col, Row, Divider } from 'antd';
import React from 'react';
import { RocketOutlined } from '@ant-design/icons';
import gif1 from '../assets/vi_1.gif';
import nwp from '../assets/spec_1.png';
import ml from '../assets/robot.svg';
import wt from '../assets/spec_3.png';
import power from '../assets/ser_img_6.png';
import team from '../assets/iner_team.png';

const Welcome: React.FC = props => {
    return (
        <div className="welcome">
            <Row align="middle" className="row1">
                <Col xs={{ span: 22, offset: 1 }} sm={{ span: 11, offset: 1 }} xl={{ offset: 5, span: 7 }}>
                    <h1>Wind &amp; solar energy forecasting</h1>
                    <p>We produce weather and energy forecasts for wind and PV farms up to 14 days ahead. Using historic data and adding individual turbine and PV plants' attributes into the calculation, we adjust our proprietary models to match closely the details of your sites.</p>
                    <p>We incorporate a variety of models into an ensemble of forecasts to achieve the best and highest resolution, making the most accurate forecasts possible.</p>
                </Col>
                <Col xs={{ span: 22, offset: 1 }} sm={{ span: 11, offset: 0 }} xl={{ span: 7, offset: 0 }}>
                    <img alt="" src={gif1} style={{ maxWidth: "100%" }}></img>
                </Col>
            </Row>
            <Row id="services" className="row2">
                <Col span={24}>
                    <h1 style={{ textAlign: "center" }}>State of the art meteorological forecast models</h1>
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="row2">
                <Col sm={{ span: 22, offset: 1 }} lg={{ span: 11, offset: 1 }} xl={{ offset: 5, span: 7 }}>
                    <Card hoverable={true}>
                        <img alt="" className="card-avatar" src={nwp}></img>
                        <span className="card-title">Numerical weather prediction</span>
                        <p>We run the most up to date numerical weather prediction models, which we combine with well established global models to generate the most accurate ensemble forecasts.</p>
                    </Card>
                </Col>
                <Col sm={{ span: 22, offset: 1 }} lg={{ span: 11, offset: 0 }} xl={{ span: 7, offset: 0 }}>
                    <Card hoverable={true}>
                        <img alt="" className="card-avatar" src={ml}></img>
                        <span className="card-title">Machine learning models</span>
                        <p>Our proprietary machine learning models, can receive real time feedback from wind turbines, in order to improve the short term forecast precision by orders of magnitude.</p>
                    </Card>
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="row2">
                <Col sm={{ span: 22, offset: 1 }} lg={{ span: 11, offset: 1 }} xl={{ offset: 5, span: 7 }}>
                    <Card hoverable={true}>
                        <img alt="" className="card-avatar" src={wt}></img>
                        <span className="card-title">High resolution forecasts</span>
                        <p>We deliver forecast updates up to <strong>4 times per hour</strong>, with high spatial &amp; temporal resolution that goes up <strong>15 minute steps and 0.5km horizontal grid density</strong>.</p>
                    </Card>
                </Col>
                <Col sm={{ span: 22, offset: 1 }} lg={{ span: 11, offset: 0 }} xl={{ span: 7, offset: 0 }}>
                    <Card hoverable={true}>
                        <img alt="" className="card-avatar" src={power}></img>
                        <span className="card-title">User friendly platform</span>
                        <p>We offer a modern web based platform, where you can access forecasts for your site(s). Furthermore, we deliver forecast data in Excel and CSV format.</p>
                    </Card>
                </Col>
            </Row>
            <Row id="about" align="middle" gutter={[16, 16]} className="row3">
                <Col sm={{ span: 22, offset: 1 }} lg={{ span: 13, offset: 1 }} xl={{ offset: 5, span: 9 }}>
                    <span className="title"><RocketOutlined /> Decades of experience</span>
                    <p>Meteogen is the product of years of research, experience and collaboration between meteorologists and software engineers. Our team also operates <a href="https://www.k24.net/" rel="noopener noreferrer">k24.net</a>, one of the biggest weather forecasting websites in Greece.</p>
                </Col>
                <Col sm={{ span: 22, offset: 1 }} lg={{ span: 9, offset: 0 }} xl={{ span: 5, offset: 0 }}>
                    <img alt="" src={team} style={{ maxWidth: "100%" }}></img>
                </Col>
            </Row>
            <Row id="register" style={{ paddingBottom: "20px" }} gutter={[16, 16]} className="row3">
                <Col style={{ textAlign: "center" }} sm={{ span: 22, offset: 1 }} xl={{ offset: 5, span: 14 }}>
                    <Divider></Divider>
                    <h1>Register now and get one month free forecasts for all your assets!</h1>
                    <Button href="/Identity/Account/Register" type="primary" size="large">Register</Button>
                </Col>
            </Row>
        </div>
    );
}

export default Welcome;