import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { message, Button, Col, Empty, Input, Modal, PageHeader, Result, Row, Space, Table, Tabs } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { AppContext } from '../state/State';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faMap, faDatabase } from '@fortawesome/free-solid-svg-icons';
import { Asset } from '../data/Asset';
import { Park } from '../data/Park';
import windTurbine from '../assets/wind-turbine.svg';
import solarPanel from '../assets/solar-panel.svg';
import windGauge from '../assets/wind-gauge.png'
import { fromLonLat } from "ol/proj";
import { boundingExtent } from "ol/extent";
import { Point } from "ol/geom";
import { RMap, ROSM, RLayerVector, RFeature, RStyle } from "rlayers";
import Highlighter from 'react-highlight-words';
import 'ol/ol.css';
import { Link, useHistory } from 'react-router-dom';
import { ExclamationCircleOutlined, PlusOutlined, SearchOutlined, DeleteFilled, EditOutlined } from '@ant-design/icons';
import { FilterConfirmProps, FilterDropdownProps } from 'antd/lib/table/interface';
import { Portfolio } from '../data/Portfolio';
import AssetData from './AssetData';

const { confirm } = Modal;

let mapCentered = false;

const Assets: React.FC = props => {
    const [loading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [data, setData] = useState<{ assets: Array<Asset>, parks: Array<Park>, portfolios: Array<Portfolio> } | null>(null);
    const [search, setSearch] = useState<{ text: string, column: React.ReactNode | null }>({ text: "", column: null });
    const context = useContext(AppContext);
    const searchInput = useRef<any>(null);
    const history = useHistory();

    const confirmDeleteAsset = (asset: Asset) => {
        confirm({
            title: `Are you sure you want to delete ${asset.name}?`,
            icon: <ExclamationCircleOutlined />,
            content: 'This action cannot be undone!',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const response = await fetch(`/api/Assets/${asset.id}`, {
                    method: 'DELETE',
                    headers: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json',
                    }
                });

                if (response.status < 400) {
                    message.success("Asset deleted.")
                    await fetchData();
                }
                else {
                    message.error(`Error deleting ${asset.name}.`)
                }
            },
            onCancel() {
            },
        });
    };

    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: (props: FilterDropdownProps) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={props.selectedKeys[0]}
                    onChange={e => props.setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(props.selectedKeys, props.confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(props.selectedKeys, props.confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(props.clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            props.confirm({ closeDropdown: false });
                            setSearch({ text: props.selectedKeys[0] as string, column: dataIndex });
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value: string | number | boolean, record: Asset) => {
            switch (dataIndex) {
                case "name":
                case "type":
                    return (record as any)[dataIndex].toString().toLowerCase().includes((value as string).toLowerCase()) as boolean;
                case "park":
                    return (record.park?.name.toString().toLowerCase() ?? "").includes((value as string).toLowerCase()) as boolean;
                case "portfolio":
                    return (record.portfolio?.name.toString().toLowerCase() ?? "").includes((value as string).toLowerCase()) as boolean;
                default:
                    return false;
            }
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text: string, record: Asset) => {
            if (dataIndex === "name") {
                if (search.column === dataIndex) {
                    return (<Link to={`/assets/${record.id}`}>
                        <Highlighter
                            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                            searchWords={[search.text]}
                            autoEscape
                            textToHighlight={text ? text.toString() : ''}
                        />
                    </Link >);
                }
                else {
                    return <Link to={`/assets/${record.id}`}>{text}</Link>;
                }
            }
            else if (search.column === dataIndex) {
                return (<Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[search.text]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />);
            }

            return text;
        },
    });

    const handleSearch = (selectedKeys: Array<React.Key>, confirm: (param?: FilterConfirmProps) => void, dataIndex: string) => {
        confirm();
        setSearch({ text: selectedKeys[0] as string, column: dataIndex });
    };

    const handleReset = (clearFilters?: () => void) => {
        clearFilters?.();
        setSearch({ text: "", column: search.column });
    };

    const fetchData = async () => {
        setLoading(true);
        setHasError(false);

        let assets: Array<Asset> = [];

        try {
            const response = await fetch(`/api/Assets`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                }
            });

            if (response.status === 200) {
                assets = await response.json();
            }
            else {
                setHasError(true);
                setLoading(false);
                return;
            }
        }
        catch (error) {
            setHasError(true);
            setLoading(false);
            return;
        }

        let parks: Array<Park> = [];

        try {
            const response = await fetch(`/api/Parks`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                }
            });

            if (response.status === 200) {
                parks = await response.json();
            }
            else {
                setHasError(true);
                setLoading(false);
                return;
            }
        }
        catch (error) {
            setHasError(true);
            setLoading(false);
            return;
        }

        assets?.forEach(x => x.park = parks?.find(y => y.id === x.parkId));

        let portfolios: Array<Portfolio> = [];

        try {
            const response = await fetch(`/api/Portfolios`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                }
            });

            if (response.status === 200) {
                portfolios = await response.json();
            }
            else {
                setHasError(true);
                setLoading(false);
                return;
            }
        }
        catch (error) {
            setHasError(true);
            setLoading(false);
            return;
        }

        assets?.forEach(x => x.portfolio = portfolios?.find(y => y.id === x.portfolioId));

        setData({ assets: assets, parks: parks, portfolios: portfolios });
        setLoading(false);
    };

    const columns: ColumnsType<Asset> = [
        {
            title: "Name",
            dataIndex: "name",
            ...getColumnSearchProps("name"),
            sorter: (a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()),
            defaultSortOrder: 'ascend',
        },
        {
            title: "Park",
            dataIndex: ["park", "name"],
            ...getColumnSearchProps("park"),
            sorter: (a, b) => (a.park?.name ?? "").toLocaleLowerCase().localeCompare((b.park?.name ?? "").toLocaleLowerCase()),
        },
        {
            title: "Portfolio",
            dataIndex: ["portfolio", "name"],
            ...getColumnSearchProps("portfolio"),
            sorter: (a, b) => (a.portfolio?.name ?? "").toLocaleLowerCase().localeCompare((b.portfolio?.name ?? "").toLocaleLowerCase()),
        },
        {
            title: "Type",
            dataIndex: "type",
            ...getColumnSearchProps("type"),
            sorter: (a, b) => a.type.localeCompare(b.type),
        },
        {
            title: "Capacity",
            dataIndex: "capacity",
            sorter: (a, b) => a.capacity - b.capacity,
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (text, record) => {
                return (<>
                    <Link to={`/assets/${record.id}`}><Button title='Edit asset' icon={<EditOutlined></EditOutlined>} type="primary" ></Button></Link>
                    <Button title='Delete asset' onClick={() => { confirmDeleteAsset(record) }} danger type="primary" icon={<DeleteFilled></DeleteFilled>}></Button>
                </>);
            }
        },
    ];

    useEffect(() => {
        fetchData();
    }, []);

    const postRenderCallback = useCallback((e) => {
        if (mapCentered || data === null || data!.assets.length === 0) {
            return;
        }

        e.map.getView().fit(boundingExtent(data!.assets.map(x => fromLonLat([x.longitude, x.latitude]))));
        mapCentered = true;
    }, [data]);

    return (
        <>
            <PageHeader
                title={context.userInfo?.organization}
                subTitle="Reports"
                style={{ paddingTop: "0" }}
            />
            {hasError &&
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Result
                            status="error"
                            title="Unable to contact server"
                            subTitle="Please check your connectivity and try again."
                            extra={[
                                <Button type="primary" key="console" onClick={fetchData}>
                                    Retry
                                </Button>
                            ]}
                        ></Result>
                    </Col>
                </Row>}

            {!hasError && (loading || data != null) &&
                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab={<span><FontAwesomeIcon icon={faList} /> List</span>} key="1">
                        <Table
                            rowKey="id"
                            loading={loading}
                            columns={columns}
                            dataSource={data?.assets}
                            summary={pageData => {
                                let totalWt = 0;
                                let totalPv = 0;
                                let totalCapacity = 0;

                                data?.assets.forEach(x => {
                                    totalWt += x.type === 'WindTurbine' ? 1 : 0;
                                    totalPv += x.type === 'PhotovoltaicArray' ? 1 : 0;
                                    totalCapacity += x.capacity;
                                });

                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell index={0}>
                                                <div>{totalWt} wind turbines</div>
                                                <div>{totalPv} PV parks</div>
                                                <div>{Math.round(10 * (totalCapacity / 1000)) / 10}MW capacity</div>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell align={'right'} index={1} colSpan={5}>
                                                <Button type='primary' onClick={() => history.push("/assets/create")} icon={<PlusOutlined />}>Add asset</Button>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell index={0} colSpan={6}>
                                                If you have a lot of assets, instead of adding them one by one, you can either send them as an Excel file to <a href="mailto:info@meteogen.com">info@meteogen.com</a> or <a href='/docs/index.html' target='_blank'>use the API</a> to add them programmatically.
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                        ></Table>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<span><FontAwesomeIcon icon={faMap} /> Map</span>} key="2">
                        <RMap
                            className="assets-map"
                            initial={{ center: [0, 0], zoom: 5 }}
                            onPostRender={postRenderCallback}
                        >
                            <ROSM />
                            <RLayerVector zIndex={10}>

                                {data?.assets.filter(x => x.type === "WindTurbine").map(x => {
                                    return (
                                        <RFeature
                                            key={`${x.id}_feat`}
                                            geometry={new Point(fromLonLat([x.longitude, x.latitude]))}
                                        // onClick={(e) =>
                                        //     e.map.getView().fit(e.target.getGeometry().getExtent(), {
                                        //         duration: 250,
                                        //         maxZoom: 15,
                                        //     })
                                        // }
                                        >
                                            <RStyle.RStyle>
                                                <RStyle.RIcon src={windTurbine} scale={0.1} anchor={[0.5, 1]} />
                                                <RStyle.RText text={x.name} scale={1.2} font="Arial 24px bold"></RStyle.RText>
                                            </RStyle.RStyle>
                                        </RFeature>
                                    );
                                })}
                            </RLayerVector>
                            <RLayerVector zIndex={11}>
                                {data?.assets.filter(x => x.type === "PhotovoltaicArray").map(x => {
                                    return (
                                        <RFeature
                                            key={`${x.id}_feat`}
                                            geometry={new Point(fromLonLat([x.longitude, x.latitude]))}
                                        // onClick={(e) =>
                                        //     e.map.getView().fit(e.target.getGeometry().getExtent(), {
                                        //         duration: 250,
                                        //         maxZoom: 15,
                                        //     })
                                        // }
                                        >
                                            <RStyle.RStyle>
                                                <RStyle.RIcon src={solarPanel} scale={0.1} anchor={[0.5, 1]} />
                                                <RStyle.RText text={x.name} scale={1.2} font="Arial 24px bold"></RStyle.RText>
                                            </RStyle.RStyle>
                                        </RFeature>
                                    );
                                })}
                            </RLayerVector>
                            <RLayerVector zIndex={12}>

                                {data?.assets.filter(x => x.type === "Other").map(x => {
                                    return (
                                        <RFeature
                                            key={`${x.id}_feat`}
                                            geometry={new Point(fromLonLat([x.longitude, x.latitude]))}
                                        // onClick={(e) =>
                                        //     e.map.getView().fit(e.target.getGeometry().getExtent(), {
                                        //         duration: 250,
                                        //         maxZoom: 15,
                                        //     })
                                        // }
                                        >
                                            <RStyle.RStyle>
                                                <RStyle.RIcon src={windGauge} scale={0.6} anchor={[0.5, 1.2]} />
                                                <RStyle.RText text={x.name} scale={1.2} font="Arial 24px bold"></RStyle.RText>
                                            </RStyle.RStyle>
                                        </RFeature>
                                    );
                                })}
                            </RLayerVector>
                        </RMap>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<span><FontAwesomeIcon icon={faDatabase} /> Upload training data</span>} key="3">
                        <AssetData assets={data?.assets}></AssetData>
                    </Tabs.TabPane>
                </Tabs>}

            {
                !hasError && !loading && data === null &&
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Empty description="No assets found"></Empty>
                    </Col>
                </Row>
            }
        </>
    );
}

export default Assets;
